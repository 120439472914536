
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, CardTitle, Label,  Form, Input, FormFeedback } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

import '../../assets/scss/style.css'
import { changePassword } from "services/userServices";
import { fireToast } from "common/Toster";
import { get, post, put, putAsForm } from "helpers/api_helper";

 
const AdminWallets = () => {
    
    const history = useHistory();
    const validation = useFormik({
      enableReinitialize: true,
  
      initialValues: {
        ethereum_address:"",
        sol_address: "",
        tron_address: "",
      },
      validationSchema: Yup.object({
        ethereum_address: Yup.string().required("Enter Your Ethereum Wallet Address"),
        sol_address: Yup.string().required("Enter Your Solana Wallet Address"),
        tron_address: Yup.string().required("Enter Your Tron Wallet Address"),
      }),
      onSubmit: async (values) => {
        try {
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };

          const loginResponse = await post("/admin/adminWallet",values,{headers})
          console.log(loginResponse.data);
          if (loginResponse.status === 200) {
            fireToast('success', loginResponse.message)
            fetchWallets();
          } else {
             throw new Error(loginResponse.error);
          }
        } catch (err) {
          // changeApiStatus(false, "");
        }
      },
    });

    
  const fetchWallets = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

      const Response = await get("/admin/mywallet", { headers });
      if (Response.status === 200) {
        // setConversionRate(Response?.data?.conversionrate)
        validation.setValues({
            ethereum_address: Response?.data?.ethereum || "",
            sol_address: Response?.data?.solana || "",
            tron_address: Response?.data?.tron || "",
        });
      } else {
        throw new Error(Response.error);
      }
    } catch (err) {
      // changeApiStatus(false, "");
    }
  };
    
  useEffect(()=>{
    fetchWallets();
  },[])


  return (
    <Row>
    <Breadcrumbs
      breadcrumbItem={("Wallet Addresses")}
    />
      <Col xl="12">
        <Card>
          <CardBody>

            <Form className="needs-validation create-vendor"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>

              <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">Ethreum Wallet Address</Label>
                    <Input
                      // className="form-control"
                      className="form-control"
                      name="ethereum_address"
                      id="ethereum_address"
                      type="text"
                      value={validation.values.ethereum_address}
                      onBlur={validation.handleBlur} onChange={validation.handleChange}
                    />
                      {validation.touched.ethereum_address && validation.errors.ethereum_address ? (
                      <FormFeedback type="invalid">{validation.errors.ethereum_address}</FormFeedback>
                    ) : null}
                  </div>
                
                </Col>
               
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">Solana Wallet Address</Label>
                    <Input
                      className="form-control"
                      name="sol_address"
                      id="sol_address"
                      type="text"
                      value={validation.values.sol_address}
                      onBlur={validation.handleBlur} onChange={validation.handleChange}
                    />
                    {validation.touched.sol_address && validation.errors.sol_address ? (
                      <FormFeedback type="invalid">{validation.errors.sol_address}</FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">Tron Wallet Address</Label>
                    <Input
                      name="tron_address"
                      className="form-control"
                      id="tron_address"
                      type="text"
                      value={validation.values.tron_address}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                    />
                    {validation.touched.tron_address && validation.errors.tron_address ? (
                      <FormFeedback type="invalid">{validation.errors.tron_address}</FormFeedback>
                    ) : null}
                  </div>
                </Col>

              
              </Row>

              <div>
                <button type="submit" className="btn btn-primary w-md mt-3">
                 Update
                </button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
  </Row>
  )
}

export default AdminWallets
