import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Icon from "react-icons-kit";
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/slogo.png";

import "../../assets/css/style.css";
import { adminLogin } from "services/userServices";
import { useHistory } from "react-router-dom";
import { fireToast } from "common/Toster";
import img from "../../assets/images/heart.gif"


const Login = props => {

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
 }
  

  //meta title
  document.title = "Login";
  const location = useLocation()
  // const params = new URLSearchParams(location.search)
  // const dispatch = useDispatch();
  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  // const [loader, setLoader] = useState(false);
  const history = useHistory();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is Required"),
      password: Yup.string().required("Password is Required")
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
    }),
    onSubmit: async (values) => {
      try {
        const loginResponse = await adminLogin(values);
        if (loginResponse.status === 200) {
          fireToast('success', loginResponse.message)
          localStorage.setItem("authUser", JSON.stringify(loginResponse.data));
          history.push("/admin/dashboard")
        } else {
          // changeApiStatus(false, "");
          // throw new Error(loginResponse.error);
        }
      } catch (err) {
        console.log(err);
        fireToast('error', err.response.data.error)
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages centerd-aligned py-5 ">

        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-theme">
                  <Row>
                    <Col xs={7}>
                      <div className=" p-4 pe-1">
                        <h5 className="text-white">Welcome Back Admin!</h5>
                        <p className="text-white">Sign in to continue Express Minds.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4 bg-soft" style={{ height: '4.5rem', width: '5.5rem' }}>
                        <span className="avatar-title rounded border bg-white">
                          <img
                            src={logo}
                            height="45"
                            className=""
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>                        
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="pass-eye position-relative">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={type}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            <span className="position-absolute" onClick={handleToggle}>
                                <Icon className="mr-10" icon={icon} size={18}/>
                            </span>
                          </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>


                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center d-none">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p className="text-white d-none">
                  © {new Date().getFullYear()} . {" "}
                  Design & Developed by Block Tech Brew
                </p>
              </div>
            </Col>
            
            <div className="text-sm-end d-none d-sm-block" style={{position:'absolute', bottom:"10px", right:'15px',color:"black"}}>
              Made with {""} 
                         <img
                            src={img}
                            style={{ width: "17px" ,height:"14px"}}
                          />   by{" "}
                           <a href="https://blocktechbrew.com/" target="_blank"  rel="noreferrer">Block Tech Brew</a>
              </div>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
