import React from "react"
import { Pie } from "react-chartjs-2"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const PieChart = ({dataColors,tokenData}) => {
  var pieChartColors =  getChartColorsArray(dataColors); 
  const data = {
    labels: ["Ethereum", "Binance","Matic","Tron","Solana"],
    datasets: [
      {
        data: [180,60,440,70,1000],
        backgroundColor: pieChartColors,
        hoverBackgroundColor: pieChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  }

  return <Pie width={650} height={460} data={data} />
}

export default PieChart;
