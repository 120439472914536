import React from "react"
import { Redirect } from "react-router-dom"

// Pages User List
import Userlist from "../pages/UserList/index"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// // Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ForgotOtp from "../pages/Authentication/ForgotOtp"
import PasswordReset from "../pages/Authentication/PasswordReset"

// Admin Dashboard
import AdminDashboard from "../admin/dashboard/index"
import AdminChangePassword from "../admin/account/index"
import AdminDeals from "../admin/deals/index"
import AdminDealsView from "../admin/deals/view"
import AdminDealsCreate from "../admin/deals/create"
import AdminLiquidityPools from "../admin/liquidity/index"
import Privacy from "pages/Privacy/Privacy"
import UserDetail from "pages/UserDetails/UserDetail"
import TradingView from "pages/Privacy/TradingView"
import Withdraw from "pages/Withdraw/Withdraw"
import AddFund from "pages/AddFund/AddFund"
// import Commission from "pages/Commission/Commission"
import AddNetwork from "pages/AddNetwork/AddNetwork"
import AddAssets from "pages/AddAssets/AddAssets"
import Terms from "pages/Terms&Conditions/Terms"
import Support from "pages/Support/Support"
import Viewbanners from "pages/Banner/banner"
import Transactions from "pages/MercuroTransactions/Transactions"




const authProtectedRoutes = [
   // User List
   { path: "/userlist", component: Userlist },
   { path: "/terms-condition", component:  Terms},
   { path: "/support", component: Support },
   { path: "/banners", component: Viewbanners },
   { path: "/privacy-policy", component:  Privacy},
  //  { path: "/userDetails/:id", component:  UserDetail},
   { path: "/withdraw", component:Withdraw  },
   { path: "/transactions", component: Transactions },
   { path: "/add-fund", component:AddFund  },
  //  { path: "/commission", component:Commission  },
   { path: "/addNetwork", component:AddNetwork  },
   { path: "/assets", component:AddAssets  },
   { path: "/viewUser", component:UserDetail  },



  
  //profile
  { path: "/profile", component: UserProfile },

  // Admin Routes
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/settings", component: AdminChangePassword },
  { path: "/admin/change-password", component: AdminChangePassword },
  { path: "/admin/deals", component: AdminDeals },
  { path: "/admin/deals/view", component: AdminDealsView },
  { path: "/admin/deals/create", component: AdminDealsCreate },
  { path: "/admin/liquidity-pools", component: AdminLiquidityPools },
  { path: "/dashboard", component: AdminDashboard },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [


  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: ForgotOtp },
  { path: "/reset-password", component: PasswordReset },
   
  { path: "/trading-news", component: TradingView },

]

export { authProtectedRoutes, publicRoutes }
