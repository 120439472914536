
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, CardTitle, Label,  Form, Input, FormFeedback } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

import '../../assets/scss/style.css'
import { changePassword } from "services/userServices";
import { fireToast } from "common/Toster";
import { get, post, put, putAsForm } from "helpers/api_helper";

 
const Commission = () => {
    
    const history = useHistory();
    const validation = useFormik({
      enableReinitialize: true,
  
      initialValues: {
        add_funds:"",
        withdraw: "",
        transfer: "",
      },
      validationSchema: Yup.object({
        add_funds: Yup.number().required("Enter Add Funds Commission Rate"),
        withdraw: Yup.number().required("Enter Withdraw Commission Rate"),
        transfer: Yup.number().required("Enter Transfer Commission Rate"),
      }),
      onSubmit: async (values) => {
        try {
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };

          const loginResponse = await put("/admin/updateCommission",values,{headers})
          if (loginResponse.status === 200) {
            fireToast('success', loginResponse.message)
            fetchWallets();
          } else {
             throw new Error(loginResponse.error);
          }
        } catch (err) {
          // changeApiStatus(false, "");
        }
      },
    });

    
  const fetchCommisions = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

      const Response = await get("/admin/getCommission", { headers });
      if (Response.status === 200) {
        console.log(Response?.data.commision);
        validation.setValues({
            add_funds: Response?.data?.Commision[0]?.add_funds || "",
            withdraw: Response?.data?.Commision[0]?.withdraw || "",
            transfer: Response?.data?.Commision[0]?.transfer || "",
        });
      } else {
        throw new Error(Response.error);
      }
    } catch (err) {
      // changeApiStatus(false, "");
    }
  };
    
  useEffect(()=>{
    fetchCommisions();
  },[])


  return (
    <Row>
    <Breadcrumbs
      breadcrumbItem={("Commission Rates (in Percentage %)")}
    />
      <Col xl="12">
        <Card>
          <CardBody>

            <Form className="needs-validation create-vendor"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>

              <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">Add Funds Commission Rate</Label>
                    <Input
                      // className="form-control"
                      className="form-control"
                      name="add_funds"
                      id="add_funds"
                      type="number"
                      value={validation.values.add_funds}
                      onBlur={validation.handleBlur} onChange={validation.handleChange}
                    />
                      {validation.touched.add_funds && validation.errors.add_funds ? (
                      <FormFeedback type="invalid">{validation.errors.add_funds}</FormFeedback>
                    ) : null}
                  </div>
                
                </Col>
               
                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">Withdraw Commission Rate</Label>
                    <Input
                      className="form-control"
                      name="withdraw"
                      id="withdraw"
                      type="number"
                      value={validation.values.withdraw}
                      onBlur={validation.handleBlur} onChange={validation.handleChange}
                    />
                    {validation.touched.withdraw && validation.errors.withdraw ? (
                      <FormFeedback type="invalid">{validation.errors.withdraw}</FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-password-Input">Transfer Commission Rate</Label>
                    <Input
                      name="transfer"
                      className="form-control"
                      id="transfer"
                      type="number"
                      value={validation.values.transfer}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                    />
                    {validation.touched.transfer && validation.errors.transfer ? (
                      <FormFeedback type="invalid">{validation.errors.transfer}</FormFeedback>
                    ) : null}
                  </div>
                </Col>

              
              </Row>

              <div>
                <button type="submit" className="btn btn-primary w-md mt-3">
                 Update
                </button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
  </Row>
  )
}

export default Commission
