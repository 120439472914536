import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import "./index.scss";
import {
  CFormSelect,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import UserImg from "../../assets/images/user.png";
import axios from "axios";
import Breadcrumb from "components/Common/Breadcrumb";
import moment from "moment";
import schedule from "../../assets/images/schedule.svg";
import Pagination from "common/Pagination";
import { fireToast } from "common/Toster";
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg";
import { get, patch, put } from "helpers/api_helper";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { StandardPicketDateFormat } from "common/utility";
import { handleClickCopy, longStringConvertToShort,fullDateFormat } from "common/commonfunction";
import { dateformat } from "admin/commonFunction";
import { APIS_URL } from "config";

const Transactions = () => {
  const [transactionsData, setTransactionsData] = useState([]);
  const [search, setSearch] = useState("");
  const inputRef = useRef(null);
  const [calendarIsShowing, setCalendarIsShowing] = useState(false);
  const [dateFilter, setDateFilter] = useState([]);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 20,
  });

  const changeStatus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const statusVal = e.target.value;
    if (statusVal === status) {
      return;
    }
    setStatus(statusVal);
  };

  useEffect(()=>{
    fetchTransactions(page.current,page.pageSize)
  },[page.current, page.pageSize, search])

const fetchTransactions = async (pageNumber,pageSize) => {
    try {

      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

    //   const [fromDate, toDate] = dateFilter || [];

    const response= await axios.get(`${APIS_URL}api/admin/AddFundsByCard?pageNumber=${pageNumber}&pageSize=${pageSize}&search${''}`,{headers});
    console.log(response?.data?.data); 
      if (response.status === 200) {
        setTransactionsData(response?.data?.data?.data)
        setPage({
          ...page,
          totalItems: response?.data?.data?.totalItems,
          current: pageNumber,
        });
      } else {
        console.log("not fetched data");
      }
    } catch (err) {
      throw err;
    }
  };




  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Breadcrumb
            title="Withdraw Requests"
            breadcrumbItem="Withdraw Requests"
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="col-12 col">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div className="page-title-right">
                        <ol className="breadcrumb ">
                          <li>
                            <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                              <div className="position-relative">
                                <input
                                  onChange={(e) => setSearch(e.target.value)}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control"
                                  placeholder={`Search...`}
                                  value={search}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </li>

                          {/* <li>
                            <div className="search-box me-xxl-2 my-5 my-xxl-0 d-inline-block mx-5">
                              <div className="position-relative">
                                <CInputGroup>
                                  <DateRangePicker
                                    initialSettings={{
                                      startDate: "1/1/2024",
                                      endDate: "3/1/2024",
                                    }}
                                    onApply={dateFilterChange}
                                    // onCancel={}
                                    onShow={() => setCalendarIsShowing(true)}
                                    onHide={() => setCalendarIsShowing(false)}
                                  >
                                    <input
                                      ref={inputRef}
                                      placeholder="Filter by Date"
                                      className="form-control"
                                      value={
                                        dateFilter.length
                                          ? `${StandardPicketDateFormat(
                                              dateFilter[0]
                                            )} - ${StandardPicketDateFormat(
                                              dateFilter[1]
                                            )}`
                                          : ""
                                      }
                                    />
                                  </DateRangePicker>
                                  <CInputGroupText>
                                    <img
                                      onClick={() => {
                                        if (
                                          !calendarIsShowing &&
                                          dateFilter.length
                                        ) {
                                          setDateFilter([]);
                                          fetchWithdrawRequests(
                                            page.current,
                                            page.pageSize,
                                            status,
                                            [],
                                            search
                                          );
                                        }
                                      }}
                                      src={
                                        calendarIsShowing
                                          ? calendarremovelines
                                          : dateFilter.length
                                          ? calendarremovelines
                                          : schedule
                                      }
                                      alt=""
                                      width={20}
                                    />
                                  </CInputGroupText>
                                </CInputGroup>
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </li> */}

                          {/* <li>
                            <div className="me-xxl-2 my-5 my-xxl-0 d-inline-block mx-5">
                              <div className="position-relative">
                                <CFormSelect
                                  className="form-control"
                                  aria-label="Small select example"
                                  onChange={(e) => changeStatus(e)}
                                  value={status}
                                >
                                  <option value="" className="form-control">
                                    All
                                  </option>
                                  <option value="0">PENDING</option>
                                  <option value="1">COMPLETED</option>
                                  <option value="2">REJECTED</option>
                                </CFormSelect>
                              </div>
                            </div>
                          </li> */}

                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="table-responsive react-table">
                      <Table bordered hover className="custom-header-css">
                        <thead className="table-light table-nowrap">
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">UserName</th>
                            <th scope="col">Crypto</th>
                            <th scope="col">Crypto Amount</th>
                            <th scope="col">Crypto Status</th>
                            <th scope="col">Fiat</th>
                            <th scope="col">Fiat Amount</th>
                            <th scope="col">Fee</th>
                            <th scope="col">Fiat Status</th>
                            <th scope="col">Created On</th>
                          </tr>
                        </thead>

                        {transactionsData?.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <strong>
                                  {item.user_details.first_name}
                                  </strong>
                                  <a className="d-block mt-2" href="mailto:">
                                    {item.user_details.email}
                                  </a>
                                
                              </td>
                              <td>
                               
                                  {item.crypto.currency}
                               
                              </td>
                              <td>
                               
                              {item.crypto.amount}
                                
                              </td>
                              <td>
                                    {
                                        item.crypto.status == 'pending'? <Badge className="bg-warning"> PENDING </Badge>    :                                                                              
                                                        <Badge className="bg-success"> Succedded </Badge> 
                                                              
                                    }
                                 </td>
                              <td>
                                
                                  {item.fiat.fiat_currency}
                               
                              </td>
                              <td>
                                
                                  {item.crypto.fiat_currency=='USD'? '$'+item.crypto.fiat_amount : item.crypto.fiat_amount}
                               
                              </td>


                             <td>{'$'+item.fiat.fee}</td>
                             <td>
                                    {
                                        item.fiat.status == 'paid'? <Badge className="bg-success"> PAID </Badge>    :                                                                              
                                                        <Badge className="bg-warning"> PENDING </Badge> 
                                                              
                                    }
                                 </td>
                              <td>{fullDateFormat(item.createdAt)}</td>
                             
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </div>
                  <Pagination setPage={setPage} page={page} data={transactionsData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
