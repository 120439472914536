import React from "react";
import moment from "moment";
import { fireToast } from "./Toster";

export const longStringConvertToShort = (str, length) => {
    let startingLength = Math.ceil(length / 4)
    if (str.length > length) {
        const prefix = str.substring(0, startingLength); // Take first startingLength characters
        const suffix = str.substring(str.length - startingLength); // Take last startingLength characters
        return `${prefix}...${suffix}`;
    }
    return str;
};

export const handleClickCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
};

export const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format('MMM DD, YYYY')}
        <br />
        <small>{moment(date).format('h:mm a')}</small>
      </>
    );
};
