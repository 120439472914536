// import { get, post, postAsForm, put } from "helpers/api_helper.js";
import appendData from "utils/formData";
import { get, post, postAsForm, put } from "../helpers/api_helper";


export const adminLogin = (data) => {
  return post("/admin/login", data);
};

export const forgotPassword = (data) => {
  return post("/admin/forgotPassword", data);
};

export const otpverify = (data) => {
  return post("/admin/verifyOtp", data);
};


export const resetPassword = (new_password, token) => {
  return post(
    "/admin/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};


export const changePassword = ({newPassword,oldPassword}, token) => {
  return post("/admin/changePassword",{ newPassword,oldPassword },
    {
      headers: { Authorization: token },
    }
  );
};

export const addBanner = (data) => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
  console.log(token,"token")
  return postAsForm("/admin/banner",
    data,
    {
      headers: { 
        Authorization: token,
        'content-type': 'multipart/form-data'
      }
    }
  )
}