import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

const CardUser = () => {
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
        <Card style={{height:"93%"}}>
          <CardBody>
            <div>
              <div className="mb-4 me-3">
                <i className="mdi mdi-account-circle text-primary h1"></i>
              </div>

              <div>
                <h5>ExpressMinds Admin</h5>
                <p className="text-muted mb-1">express@yopmail.com</p>
              </div>
            </div>
          </CardBody>

          <CardBody className="border-top">
            <Row>
              <div className="col-sm-6">
                <div>
                  <p className="fw-medium mb-2">Balance :</p>
                  <h4>$ 0.00</h4>
                </div>
              </div>
            </Row>
            
          </CardBody>
        </Card>
    </React.Fragment>
  )
}

export default CardUser
