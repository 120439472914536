import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip,
    Input,
    FormFeedback,
    Label,
    Form,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
import { get, post, postAsForm } from 'helpers/api_helper';
import { fireToast } from 'common/Toster';

export default function AddAssetsModal({show,setShow,toggle,data}) { 

      const [myimage, setMyImage] = useState("");
      const [networkId,setNewWorkId] =useState("")
      const[networkList,setNetworkList]=useState([]);

      const uploadFile =  (files) => {
        if (files && files.length > 0) {
          setMyImage(files[0].name);
        }
      };

    const fetchNetworkList=async ()=>{
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };
            const response=await get("/admin/networks",{headers}) 
            if (response.status === 200) {
              setNetworkList(response?.data?.items);
            }
    }

    useEffect(()=>{
       fetchNetworkList()
    },[]);
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name:  '',
            symbol:"",
            active:false,
            decimals:"",
            contractAddress:"",
        },

        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Asset Name"),
            symbol: Yup.string().required("Please Enter Symbol"),
            active: Yup.boolean().required("Active Status for Asset"),
            decimals: Yup.string().required("Please Enter decimals of assets"),
            contractAddress: Yup.string().required("Please Enter contract Address"),
        }),

        onSubmit:async (values) => {
          try {
            if(myimage == undefined || myimage == ""){
              fireToast("error","Image is required.")
            }
            else{
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };
            const isActive = values.active === 'true';
            const response = await post(`/admin/assets`, {...values, assetImage: myimage,active:isActive,networkId:networkId} , { headers });
            if (response.status == 200) {
              setShow(false)
              fireToast("success", response.message);
              data()
            }
           }
          } catch (error) {
            fireToast("error", error);
          }
        },
      });

      return (
        <div >
         <Modal isOpen={show} toggle={toggle} setShow={setShow}>
                    <ModalHeader tag="h4" toggle={toggle}>
                        Add Asset
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                      <Row className="mb-3">
                          <label className="col-md-5 col-form-label">Network</label>
                          <div className="col-md-12">
                            <select className="form-control" onChange={(e)=>setNewWorkId(e.target.value)}>
                              <option>Select Network</option>
                              {networkList?.map((items, index)=>
                                (
                                  <option key={index} value={items._id}>{items.name}</option>
                                )
                            )}
                            </select>
                         </div>
                     </Row>

                        <Row> 
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name && validation.errors.name ? true : false
                                }
                              />
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Symbol</Label>
                              <Input
                                name="symbol"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.symbol || ""}
                                invalid={
                                  validation.touched.symbol && validation.errors.symbol ? true : false
                                }
                              />
                              {validation.touched.symbol && validation.errors.symbol ? (
                                <FormFeedback type="invalid">{validation.errors.symbol}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Decimals</Label>
                              <Input
                                name="decimals"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.decimals || ""}
                                invalid={
                                  validation.touched.decimals && validation.errors.decimals ? true : false
                                }
                              />
                              {validation.touched.decimals && validation.errors.decimals ? (
                                <FormFeedback type="invalid">{validation.errors.decimals}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Contract Address</Label>
                              <Input
                                name="contractAddress"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contractAddress || ""}
                                invalid={
                                  validation.touched.contractAddress && validation.errors.contractAddress ? true : false
                                }
                              />
                              {validation.touched.contractAddress && validation.errors.contractAddress ? (
                                <FormFeedback type="invalid">{validation.errors.contractAddress}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Active</Label>
                              <Input
                                name="active"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.active || ""}
                                invalid={
                                  validation.touched.active && validation.errors.active ? true : false
                                }
                              />
                              {validation.touched.active && validation.errors.active ? (
                                <FormFeedback type="invalid">{validation.errors.active}</FormFeedback>
                              ) : null}
                            </div>

                        
                            <div className="col-md-5">
                            <label htmlFor="image">Asset Image</label>
                            <input
                              className={`form-control`}
                              type="file"
                              id="file"
                              onChange={(e)=>uploadFile(e.target.files)}
                            />
                            <div className="mt-3 mb-4">
                              <div className="f-28 fw-900 text-white">{ }</div>
                            </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
        </div>
    )
}
