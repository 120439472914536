import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import CardUser from "./CardUser";
import PieChartUser from "./chart/PieChartUser";
import LineChart from "./chart/LineChart";
import BarChart from "./chart/BarChart";
import { get } from "helpers/api_helper";
import PieChart from "./chart/PieChart";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "common/chartsDynmicColors";

const Dashboard = () => {
  
  document.title = "ExpressMinds Admin Dashboard";

  const apexCardUserChartColors = getChartColorsArray('["#089981"]')
  const dataColors='["#f1bd66", "#f47e7f ", "#50ca9f"]'
  var pieChartColors = getChartColorsArray(dataColors);

  const[usersData,setUsersData]=useState({
          blockedUser:0,
          verifiedUser:0,
          totalUser:0
  })

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories:
        [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  const [series, setSeries] = useState([
    {
      name: "Withdraw",
      data: [0, 0, 3, 7, 2, 0, 0, 0, 0, 0, 0, 0],
    },
  ])


  const[monthlyUser,setmonthlyUser]=useState([]);
  const fetchUsers = async () => {
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };
  
    try {
      const response = await get(
        `/admin/userListing?search=${''}&pageNumber=${1}&pageSize=${20}`,
      { headers }
      );
        setUsersData({
          blockedUser: response.data.blockedusers,
          verifiedUser: response.data.verifiedusers,
          totalUser: response?.data?.totalUsers
        });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchMonthlyUsers = async () => {
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };
  
    try {
      const response = await get(
        `/admin/dashboard`,
      { headers }
      );
      setmonthlyUser(response?.data?.final);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {
    fetchMonthlyUsers();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
          <Row>

            <Col lg="4" >
            <CardUser  />
            </Col>

              <Col lg="4">
              <Card style={{height:"93%"}}>
                <CardBody>
                  <CardTitle>User Management</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <PieChartUser dataColors='["blue","lightgreen", "--bs-red","--bs-info", "--bs-success"]' usersData={usersData}/>
                  </div>
                </CardBody>
              </Card>
              </Col>

              <Col lg="4">
              <Card style={{height:"93%"}}>
                <CardBody>
                  <CardTitle>Available Assets</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <PieChart dataColors='["blue","lightgreen", "--bs-red","--bs-info", "--bs-success"]'/>
                  </div>
                </CardBody>
              </Card>
              </Col>

          </Row>
          
          <Row>
            <Col lg={6}>
              <Card style={{height:"93%"}}>
                <CardBody>
                  <CardTitle className="mb-4">Monthly Registered Users</CardTitle>
                  <BarChart dataColors='["--bs-success-rgb, 0.8", "--bs-success"]' monthlyRegistereddata={monthlyUser}/>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" sm="5">
           <Card className="mb-4" style={{padding: "20px"}}>
            <CardBody className="" style={{border: "1px solid #e0e3eb", borderRadius: "2px"}}>
              <h4 className="card-title mb-4">Withdraw Requests</h4>
              <div className="mt-4">
                <div id="wallet-balance-chart" className="mt-2">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="area"
                    height={300}
                    className="apex-charts"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
          </Row>

         

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
