import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, Button } from "reactstrap";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/mainlogo.png";
import logoLightSvg from "../../assets/images/mainlogo.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import CIcon from '@coreui/icons-react';
import { fireToast } from 'common/Toster';
import { cilLockLocked } from '@coreui/icons';

const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [myaccount, setMyaccount] = useState(localStorage.getItem('myaccountadd'))

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  
  const activatemyaccount = async () => {
    if (window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0x13881", 
          },
        ], 
      });
      const walletAddress = await window.ethereum.send('eth_requestAccounts')
      setMyaccount(walletAddress?.[0] ? walletAddress?.[0] : walletAddress?.result?.[0])
      localStorage.setItem(
        'myaccountadd',
        walletAddress?.[0] ? walletAddress?.[0] : walletAddress?.result?.[0],
      )
    } else {
      fireToast('error', 'Please add wallet')
      setMyaccount('')
      localStorage.removeItem('myaccountadd')
    }
  }

  const deactivatemyaccount = () => {
    localStorage.removeItem('myaccountadd')
    setMyaccount('')
  }

  // useEffect(async () => {
  //   if (window.ethereum) {
  //     try {
  //       const walletAddress = await window.ethereum.send('eth_requestAccounts')
  //       if (walletAddress) {
  //         localStorage.setItem(
  //           'myaccountadd',
  //           walletAddress?.[0] ? walletAddress?.[0] : walletAddress?.result?.[0],
  //         )
  //         setMyaccount(walletAddress)
  //       } else {
  //         localStorage.removeItem('myaccountadd')
  //         setMyaccount('')
  //       }
  //     } catch (err) {
  //       setMyaccount('')
  //       localStorage.removeItem('myaccountadd')
  //     }
  //   } else {
  //     fireToast('error', 'Please add wallet')
  //     setMyaccount('')
  //     localStorage.removeItem('myaccountadd')
  //   }
  // }, [])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header bg-white">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none  d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link> 

              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" style={{color:"black"}}/>
            </button>

          </div>
          <div className="d-flex" style={{paddingBottom: "13px" }}>
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          <div  className="dropdown d-inline-block d-none d-lg-inline-block ms-1 mt-4 text-end pe-2 text-black">
            {myaccount ? (
            <>
              <div style={{ border: '10px' }} className="m-btn cursor-pointer"  onClick={deactivatemyaccount}>
                <Button>
                  {localStorage.getItem('myaccountadd')
                    ? localStorage.getItem('myaccountadd')?.slice(0, 6)
                    : myaccount?.toString()?.slice(0, 6)}
                  ...
                  {localStorage.getItem('myaccountadd')
                    ? localStorage.getItem('myaccountadd')?.slice(36, 42)
                    : myaccount?.toString()?.slice(36, 42)}

                <CIcon
                  icon={cilLockLocked}
                  customClassName="nav-icon"
                  style={{height: "17px",
                    paddingBottom: "3px",
                    paddingLeft: "3px"}}
                  onClick={deactivatemyaccount}
                />
                </Button>
               
              </div>
            </>
          ) : (
            <Button className="btn btn-primary border-primary pointer " onClick={activatemyaccount}>
              Connect Wallet
            </Button>
          )}
        </div>

            <ProfileMenu /> 
            <div
               onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >

              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle pt-22 "
              >
              </button>

            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
