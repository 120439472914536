import { get } from "helpers/api_helper";
import React, { useEffect, useState, useMemo } from "react";
import { APIS_URL } from "config";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Form } from "react-bootstrap";
import AddAssetsModal from "./AddAssetsModal";
import Pagination from "common/Pagination";
import { CFormSwitch } from "@coreui/react";
import { fireToast } from "common/Toster";
import axios from "axios";
import avatar from '../../assets/images/Logo.png'

export default function AddAssets() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 10,
  });

  const [recentFilePage, setRecentFilePage] = useState("");

  const assetsList = async (pageNumber, pageSize) => {
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };

    const response = await get(
      `admin/assets?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      { headers }
    );
    if (response.status === 200) {
      setData(response?.data?.assets);
      setRecentFilePage(response?.data?.assets);
      setPage({
        ...page,
        totalItems:response?.data?.totalCount,
        current: pageNumber,
      });
    } else {
      console.log("not fetched data");
    }
  };

  const toggle = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    assetsList(page.current,page.pageSize);
  }, [page.current, page.pageSize]);

  const handleAddClick = () => {
    setShow(true);
  };

  const handleactiveInactive = async (
    status,
    id,
    pageNumber,
    pageSize
  ) => {

    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };
    try {
      const params = {
        id: id,
        isActive: status
      };

      let response = await axios.put(
        `${APIS_URL}api/admin/updateActiveAsset`,
        params,
        { headers }
      );
      
      if (response.status == 200) {
        fireToast("success",response.data.message);
        assetsList(pageNumber, pageSize);
      } else {
        fireToast('error',response.error)
      }
    } catch (error) {
      // fireToast('error',response.error)
    }
  };

  return (
    <>
      <React.Fragment>
        <AddAssetsModal show={show} setShow={setShow} toggle={toggle} data={assetsList}/>
        <div className="page-content margin-custom ">
          <Breadcrumb title="Assets" breadcrumbItem="Assets" />
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {/* <div className="col-12 col"> */}
                      {/* <div className="page-title-box d-sm-flex align-items-center justify-content-between"> */}
                        {/* <div className="page-title-right"> */}
                          {/* <ol className="breadcrumb "> */}
                            {/* <li>
                              <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                <div className="position-relative">
                                  <input
                                    onChange={(e) => setSearch(e.target.value)}
                                    id="search-bar-0"
                                    type="text"
                                    className="form-control"
                                    placeholder={`Search...`}
                                    value={search}
                                  />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </li> */}
                          {/* </ol> */}
                        {/* </div> */}
                      {/* </div> */}
                    {/* </div> */}

                  
                    <Col sm="12">
                    <p style={{fontSize:'20px'}}>Total Assets : {data.length}</p>
                      <div className="text-sm-end d-flex justify-content-end ">
                        
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2 "
                          onClick={handleAddClick}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add Asset
                        </Button>
                      </div>
                    </Col>

                    <div className="col-12">
                      <div className="table-responsive react-table">
                        <Table bordered hover className="custom-header-css">
                          <thead className="table-light table-nowrap">
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col">Logo</th>
                              <th scope="col">Asset Name</th>
                              <th scope="col">Asset Symbol</th>
                              <th scope="col">Network</th>
                              <th scope="col">Network Explorer URL</th>
                              <th scope="col">Network ChainId</th>
                              <th scope="col">Active</th>
                            </tr>
                          </thead>

                          {data?.map((item, index) => (
                            <tbody key={index}>
                              <tr>
                              <td>
                                  {index +
                                    1 +
                                    (page.current - 1) * page.pageSize}
                                </td>
                                <td><img src={item.assetImage ? item.assetImage : avatar} alt="" className="avatar-sm" /></td>
                                <td>
                                  <strong>{item.name}</strong>
                                </td>
                                
                                <td>
                                {item.symbol}
                                </td>
                                <td>
                              {item.networkDetails[0].name}
                                </td>
                                <td>
                                    <a href={`${item.networkDetails[0].explorerUrl}`} target="_blank"  rel="noreferrer">
                                        {item.networkDetails[0].explorerUrl}
                                    </a>
                                </td>

                                <td>
                                <tr>{item.networkDetails[0].chainId}</tr>
                                </td>

                                <td>
                                  <CFormSwitch
                                    shape="pill"
                                    className="hand"
                                    style={{ cursor: "pointer" }}
                                    onChange={(e) => handleactiveInactive(e.target.checked, item._id, page.current,
                                    page.pageSize)}
                                    defaultChecked={item.active ? 1 : 0}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      </div>
                    </div>
                    {/* <Pagination setPage={setPage} page={page} data={data} /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
