import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import { withRouter, Link, useLocation } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/slogo.png";

import "../../assets/css/style.css";
import { useHistory } from "react-router-dom";
import { resetPassword } from "services/userServices";
import { fireToast } from "common/Toster";
import Icon from "react-icons-kit";

const PasswordReset = (props) => {
  const [value, setValue] = useState("");

  const handlePaste = (e) => {
    e.preventDefault();
  };

  document.title = "Reset-Password";
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const history = useHistory();
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
      confirm: "",
    },

    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("This Field is Required")
        .min(8, "Password must be 8 characters long")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[0-9]/, "Password requires a number")
        .matches(
          /[^a-zA-Z0-9]/,
          "Password requires a symbol (excluding underscore)"
        ),

      confirm: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        'Must match "password" field value'
      ),
    }),

    onSubmit: async (values) => {
      const obj = localStorage.getItem("authUser");
      try {
        const loginResponse = await resetPassword(values.newPassword, obj);
        if (loginResponse.status === 200) {
          fireToast("success", loginResponse.message);
          history.push("/login");
        } else {
          throw new Error(loginResponse.error);
        }
      } catch (err) {
        fireToast("error", err.response.data.error);
      }
    },
  });
  return (
    <React.Fragment>
      <div className="account-pages centerd-aligned py-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft" style={{ background: "#427CED" }}>
                  <Row>
                    <Col xs={7}>
                      <div className=" p-4 pe-1">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p className="text-white">
                          Enter new password with Express-Minds.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/login" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-4 bg-soft"
                        style={{ height: "4.5rem", width: "5.5rem" }}
                      >
                        <span className="avatar-title rounded border bg-white">
                          <img src={logo} height="45" className="" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="needs-validation create-vendor"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              New Password
                            </Label>
                            <div className="pass-eye position-relative">
                              <Input
                                type={type}
                                name="newPassword"
                                className="form-control"
                                id="newPassword"
                                placeholder="Enter Your Password"
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                              />
                              <span
                                className="position-absolute"
                                onClick={handleToggle}
                              >
                                <Icon className="mr-10" icon={icon} size={18} />
                              </span>
                            </div>
                            {validation.touched.newPassword &&
                            validation.errors.newPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.newPassword}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">
                              Confirm Password
                            </Label>
                            <Input
                              type="password"
                              name="confirm"
                              className="form-control"
                              id="confirm-password"
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              placeholder="Enter Confirm Password"
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                            />
                            {validation.touched.confirm &&
                            validation.errors.confirm ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirm}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-0 text-center">
                        <p>
                          Go back to{" "}
                          <Link
                            to="login"
                            className="font-weight-medium text-primary"
                          >
                            Login Page
                          </Link>{" "}
                        </p>
                      </div>

                      <Col md={10}>
                        <div className="text-sm-end d-none d-sm-block">
                          Design & Developed by{" "}
                          <a href="https://blocktechbrew.com/" target="_blank"  rel="noreferrer">
                            BlockTechBrew
                          </a>
                        </div>
                      </Col>

                      <div>
                        <button type="submit" className="btn w-100 mt-3">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PasswordReset);

PasswordReset.propTypes = {
  history: PropTypes.object,
};
