import PropTypes, { element, func } from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useFormik } from "formik";
import Modal from 'react-bootstrap/Modal';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css"
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withTranslation } from "react-i18next"
import * as Yup from "yup";
import { fireToast } from 'common/Toster';
import axios from 'axios';
import { addBanner } from "services/userServices";


const Addbanner = ({ addInfo, onHide, show, getListing }) => {
    const [file, setFile] = useState("null")
    // console.log(file, "file")

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {},
        validationSchema: Yup.object({

        }),

        onSubmit: async (values) => {
            try {
                if (file && file != "null") {
                    console.log(values, "<<-- values")
                    const formData = new FormData()
                    formData.append('file', file)

                    console.log(formData, "formdata")
                    const resp = await addBanner(formData);
                    console.log(resp, "resp")
                    if (resp.status == 200) {
                        console.log(resp, "success adding picture ");
                        fireToast("success", resp.message)
                        onHide();
                        getListing()
                        // localStorage.setItem("authUser", JSON.stringify(resp?.data));
                        // history.push("/admin/dashboard-list");
                    }
                }
            } catch (error) {
                fireToast('error', error?.response?.data?.error)
            }
            // dispatch(loginUser(values, props.history));
            //dispatch(loginUser(values, props.history));
            // adminLogin(values);
        }
    });

    //   const handleFileChange = (e) => {
    //     validation.setFieldValue('file', e.target.files[0]);
    //   };

    const options = [
        { value: "AK", label: "Alaska" },
        { value: "HI", label: "Hawaii" },
        { value: "CA", label: "California" },
        { value: "NV", label: "Nevada" },
        { value: "OR", label: "Oregon" },
        { value: "WA", label: "Washington" },
    ]

    return (
            <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ height: "100%" }}
                className='img-view-modal'
            >
                <Modal.Header closeButton className=''>
                    
                </Modal.Header>

                <Modal.Body> 
            <div >

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <CardTitle>Add Banner</CardTitle>
                                <p className="card-title-desc mb-4">
                                    Fill the information below
                                </p>

                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <Row>
                                        <Col sm="6" className="mt-3">
                                            <Label htmlFor="colletral_token">Banner Image</Label>
                                            <Input
                                                id="file"
                                                name="file"
                                                type="file"
                                                className="form-control"
                                                placeholder="Write here..."
                                                onChange={(e) => [setFile(e.target.files[0]), validation.handleChange]}
                                                invalid={(!file && file != "null") ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="d-flex flex-wrap gap-2 mt-4">
                                        <Button type="submit" color="primary" className="btn" onClick={() => file == "null" && setFile("")}>
                                            Add Now
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

            </div>
            </Modal.Body>
            </Modal>
        </>
    )
}

Addbanner.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(Addbanner)
