import axios from "axios";
import React, { useEffect, useState, useLocation, useContext } from "react";
import { useParams } from "react-router-dom";
import "./userdetails.scss";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  handleClickCopy,
  longStringConvertToShort,
} from "common/commonfunction";
import { JobWidgetCharts } from "./chart";
import { Breadcrumbs } from "@material-ui/core";
import avatar1 from "../../assets/images/users/dummy.png";
import { CFormSelect } from "@coreui/react";
import { get } from "helpers/api_helper";
import { getImage } from "common/getImageUrl";
import { APIS_URL } from "config";
import { LoaderContext } from "context/ContextProvider"

const UserDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [userWalletAddress, setUserWalletAddress] = useState([]);
  const [transListing, setTransListing] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [assetId, setAssetId] = useState("");
  const [assetName, setAssetName] = useState("");
  const [explorerUrl, setExplorerUrl] = useState("");
  const [loading, setLoading] = useState(true)
  // const {loader,loading, setLoading} = useContext(LoaderContext)//useState(true);


  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const viewUser = async (id) => {
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };

    const response = await axios.get(
      `${APIS_URL}api/admin/ViewAUser?userId=${id}`,
      { headers }
    );
    if (response.status === 200) {
      setData(response?.data?.data?.user);
    } else {
      console.log("data not found");
    }
  };

  const userWalletAddresses = async (id) => {

    console.log("api calling ------------------------")
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };

    setLoading(true);
    const response = await axios.get(
      `${APIS_URL}api/admin/wallets/addresses?userId=${id}`,
      { headers }
    );
    console.log(response, "<<<----------response")

    if (response.status === 200) {
      setUserWalletAddress(response?.data?.data);
      setLoading(false);
    } else {
      console.log("data not found");
    }
  };



  const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format("MMM DD, YYYY")}
        <br />
        <small>{moment(date).format("h:mm a")}</small>
      </>
    );
  };

  useEffect(() => {
    assetsList();
    fetchTransactionLists(history.location.state.state, assetId);
  }, [assetId]);

  useEffect(() => {
    viewUser(history.location.state.state);
    userWalletAddresses(history.location.state.state);
  }, []);

  const fetchTransactionLists = async (userId, assetId) => {
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };

    const response_assetlisting = await get(`admin/assets?pageNumber=${""}&pageSize=${""}`, {
      headers,
    });

    console.log(response_assetlisting?.data?.assets, "assetss listttttt");
    console.log(assetId, "assetis <<<<<<<<<<<<<<<<<")
    const asset_first = response_assetlisting?.data?.assets[0]._id
    console.log(assetId, "??????>>>>>>>")
    if (assetId == "") {
      assetId = asset_first
    }
    console.log(assetId, "??????>>>>>>> later")

    const response = await axios.get(
      `${APIS_URL}api/admin/transactions?userId=${userId}&assetId=${assetId}`,
      { headers }
    );

    if (response.status === 200) {
      console.log(response?.data?.data?.transactions?.result, "response>>>>>>>>>>>>>>>>>>>")
      setTransListing(response?.data?.data?.transactions);
      setExplorerUrl(response?.data?.data?.explorerUrl)
    } else if (response.status === 400) {
      setTransListing([])
      console.log("data not found");
    } else {
      console.log("data");
    }
  };

  const assetsList = async (pageNumber, pageSize) => {
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };

    const response = await get(`admin/assets?pageNumber=${""}&pageSize=${""}`, {
      headers,
    });

    console.log(response?.data?.assets, "assetss listttttt");
    if (response.status === 200) {
      setAssetData(response?.data?.assets);
      // setAssetId(response?.data?.assets[0]._id)
      // setRecentFilePage(response?.data?.assets);
      // setPage({
      //   ...page,
      //   totalItems:response?.data?.totalCount,
      //   current: pageNumber,
      // });
    } else {
      console.log("not fetched data");
    }
  };

  const handleAssets = async (e) => {
    setAssetId(e.target.value);
    // setAssetName(e.target.value.name)
  };
  console.log(transListing, "translisting")

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Users" breadcrumbItem="User Detail" />
          <Button className="btn btn-success mb-3" onClick={handleGoBack}>
            Back
          </Button>

          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <img
                          src={
                            data.profilePic ? getImage(data.profilePic) : avatar1
                          }
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>

                      <div className="text-muted">
                        <h5> {data ? data?.first_name : ""} </h5>
                        {data?.walletAddress ? (
                          <>
                            <b className="mb-0">
                              {" "}
                              <i className="mdi mdi-wallet"></i>{" "}
                              {longStringConvertToShort(
                                data?.walletAddress,
                                30
                              )}{" "}
                              <i
                                role="button"
                                onClick={() =>
                                  handleClickCopy(data?.walletAddress)
                                }
                                className="mdi mdi-content-copy ms-2"
                              ></i>{" "}
                            </b>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top py-4">
                  <ul className="list-unstyled mt-2">
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-phone text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Phone</h6>
                          <p className="text-muted fs-14 mb-0">
                            {" "}
                            {data?.phone ? data?.phone : "-"}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-mail-send text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Email</h6>
                          <p className="text-muted fs-14 mb-0">
                            {data?.email}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-map text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Location</h6>
                          <p className="text-muted fs-14 mb-0">
                            {data?.residence_country}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-globe text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Created On</h6>
                          <p className="text-muted fs-14 text-break mb-0">
                            {" "}
                            {fullDateFormat(data?.createdAt)}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              {
                (userWalletAddress && userWalletAddress.length > 0) ? <>
                  <Row>
                    {userWalletAddress.map((item, index) => (
                      <Col sm="4" key={index}>
                        <Card className="mini-stats-wid">
                          <CardBody className="ps-3 pe-1">
                            <div className="d-flex">
                              <div className="avatar-sm mx-auto me-3">
                                <span className="avatar-title rounded-circle bg-warning bg-soft text-warning">
                                  <i className="mdi mdi-signal-hspa h3 mb-0" />
                                </span>
                              </div>
                              <div className="flex-grow-1">
                                <p className="text-muted mb-2">
                                  {item.infrastructureType.charAt(0).toUpperCase() +
                                    item.infrastructureType.slice(1)}{" "}
                                  Wallet Balance
                                </p>
                                <h5 className="mb-0">
                                  {item.walletWiseBalance.toFixed(10)}
                                </h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  <Card>
                    <CardBody className="border-top py-4">
                      <ul className="list-unstyled mt-2">
                        {userWalletAddress.map((address, index) => (
                          <li key={index} className="mt-4">
                            <div className="d-flex">
                              <i className="bx bx-wallet-alt text-primary fs-4"></i>
                              <div className="ms-3">
                                <h6 className="fs-14 mb-2">
                                  {address.infrastructureType
                                    .charAt(0)
                                    .toUpperCase() +
                                    address.infrastructureType.slice(1)}{" "}
                                  Wallet Address:
                                </h6>
                                {address.walletAddress ? (
                                  <>
                                    <b className="mb-0">
                                      {" "}
                                      {longStringConvertToShort(
                                        address.walletAddress
                                      )}{" "}
                                      <i
                                        role="button"
                                        onClick={() =>
                                          handleClickCopy(address.walletAddress)
                                        }
                                        className="mdi mdi-content-copy ms-2"
                                      ></i>{" "}
                                    </b>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card>
                </> : <>

                  <Card className="mini-stats-wid" style={{ height: "94%" }}>
                    <CardBody className="ps-3 pe-1">
                      <div id="preloader" className="customInbuildloader" style={{ display: "contents" }}>
                        <div id="status">
                          <div className="spinner-chase">
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              }

            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div
                    className="page-title-right"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 className="card-title mb-4"> Latest Transactions </h4>
                    <ol className="breadcrumb ">
                      <li>
                        <div className="me-xxl-2 my-5 my-xxl-0 d-inline-block mx-5">
                          <div className="position-relative">
                            <CFormSelect
                              className="form-control"
                              aria-label="Small select example"
                              onChange={(e) => handleAssets(e)}
                              value={assetId}
                            >
                              {/* <option value={assetData?.[0]?._id}>Select Asset</option> */}
                              {assetData?.map((items, i) => (
                                <option value={items._id} key={i}>
                                  {items.name} ({items.networkDetails[0].name})
                                </option>
                              ))}
                            </CFormSelect>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th scope="col">From</th>
                          <th scope="col">To</th>
                          <th scope="col">Transaction ID</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                          <th scope="col" colSpan="2">
                            Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(transListing) &&
                          transListing.length > 0 ? (
                          transListing.slice(-8).map((asset, key) => (
                            <tr key={key}>
                              <td className="my-3">
                                {asset.from == "0" ? (
                                  "-"
                                ) : (
                                  <>
                                    <div title={asset.from}>
                                      {asset.from
                                        ? asset.from.slice(0, 9) +
                                        "...." +
                                        asset.from.slice(
                                          asset.from.length - 5
                                        )
                                        : " - "}{" "}
                                      <span
                                        onClick={() =>
                                          handleClickCopy(asset.from)
                                        }
                                      >
                                        <i
                                          className="mdi mdi-content-copy ms-2 fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleClickCopy(asset?.from)
                                          }
                                        />
                                      </span>
                                    </div>
                                  </>
                                )}
                              </td>
                              <td className="my-3">
                                {asset.to == "0" ? (
                                  "-"
                                ) : (
                                  <>
                                    <div title={asset.to}>
                                      {asset.to
                                        ? asset.to.slice(0, 9) +
                                        "...." +
                                        asset.to.slice(asset.to.length - 5)
                                        : " - "}{" "}
                                      <span
                                        onClick={() =>
                                          handleClickCopy(asset.to)
                                        }
                                      >
                                        <i
                                          className="mdi mdi-content-copy ms-2 fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleClickCopy(asset?.to)
                                          }
                                        />
                                      </span>
                                    </div>
                                  </>
                                )}
                              </td>
                              <td className="my-3">
                                {asset.hash == "0" ? (
                                  "-"
                                ) : (
                                  <>
                                    <div title={asset.hash}>
                                      {asset.hash
                                        ? asset.hash.slice(0, 9) +
                                        "...." +
                                        asset.hash.slice(
                                          asset.hash.length - 5
                                        )
                                        : asset.txID
                                          ? asset.txID.slice(0, 9) +
                                          "...." +
                                          asset.txID.slice(
                                            asset.txID.length - 5
                                          )
                                          : " - "}{" "}
                                      <span
                                        onClick={() =>
                                          handleClickCopy(asset.hash)
                                        }
                                      >
                                        <i
                                          className="mdi mdi-content-copy ms-2 fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleClickCopy(asset?.hash)
                                          }
                                        />
                                      </span>
                                    </div>
                                  </>
                                )}
                              </td>
                              <td>
                                <div className="text-muted">{asset?.amt}</div>
                              </td>
                              <td>
                                <div className="text-muted">
                                  {asset.txreceipt_status == "1" ? (
                                    <Badge bg="success">COMPLETED</Badge>
                                  ) : (
                                    <Badge bg="warning" className="text-black">
                                      PENDING
                                    </Badge>
                                  )}
                                </div>
                              </td>
                              <td style={{ width: "120px" }}>
                                <a
                                  href={`${explorerUrl}/tx/${asset.hash ? asset.hash : asset.txID}`}
                                  className="btn btn-primary btn-sm w-xs"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View on Explorer
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">
                              No Transactions to be shown for selected asset
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserDetail;
