import React from "react"
import { Container, Row, Col } from "reactstrap"
import img from "../../assets/images/heart.gif"
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div className="text-sm-end d-none d-sm-block">
              Made with {""} <img
                            src={img}
                            style={{ width: "17px" ,height:"14px"}}
                          />   by{" "}
                           <a href="https://blocktechbrew.com/" target="_blank" rel="noreferrer">Block Tech Brew</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
