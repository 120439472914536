import axios from "axios";
import { downloadFile } from "common/downLoad";
import { APIS_URL } from "config";

// pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = `${APIS_URL}api`;

export const axiosApi = axios.create({
  baseURL: API_URL,
});

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}
export async function getWithExportFile(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => {
    if (response.status && !response.data.data) {
      const fileUrl = URL.createObjectURL(
        new Blob([response.data], { type: `text/csv` })
      );
      downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
    }
    return response.data;
  });
}

export async function postWithExportFile(url, data, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      if (response.status && !response.data.data) {
        const fileUrl = URL.createObjectURL(
          new Blob([response.data], { type: `text/csv` })
        );
        downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
      }
      return response.data;
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function postAsForm(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}
export async function putAsForm(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}
export async function patch(url, data, config = {}) {
  return axiosApi.patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, id, config = {}) {
  return await axiosApi
    .delete(
      url,
      {
        params: { id },
      },
      { ...config }
    )
    .then((response) => response.data);
}

export const apiHeader = () => {
  const obj = JSON.parse(localStorage.getItem("user-info"));
  // if (obj && obj.data.token) {
  //   axiosApi.defaults.headers.common["Authorization"] = obj.data.token;
  // } else {
  //   return {};
  // }
};
