import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row, Container, CardTitle, Label,  Form, Input, FormFeedback } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'
import { changePassword } from "services/userServices";
import toastr from "toastr";
import { fireToast } from "common/Toster";
import AdminWallets from "./AdminWallets";
import Commission from "./Commission";
const Dashboard = props => {

  //meta title
  document.title = "Settings";
  
  const history = useHistory();
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldPassword:"",
      newPassword: "",
      confirm:"",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        // .email("Invalid User")
        .required("This Field is Required"),
        newPassword: Yup.string()
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
      confirm: Yup
        .string()
        .oneOf([Yup.ref('newPassword'), null], 'Must match "password" field value'),
    }),
    onSubmit: async (values) => {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        const loginResponse = await changePassword({newPassword:values.newPassword,oldPassword:values.oldPassword},obj.token).then((res)=>{
          fireToast('success', res.message)
        }).catch((err)=>{
          fireToast('error',err.response.data.error)
        })
      } catch (err) {
        fireToast('error', loginResponse.error)
      }
    },
  });

  
  return (
    <React.Fragment>
      <div className="page-content margin-custom">  
        <Container fluid> 
        <Breadcrumbs
            breadcrumbItem={props.t("Change Password")}
          />
        <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form className="needs-validation create-vendor"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Old Password</Label>
                          <Input
                            type="password"
                            name="oldPassword"
                            className="form-control"
                            id="old-password"
                            placeholder="Enter Your Password"
                            onBlur={validation.handleBlur} onChange={validation.handleChange}
                          />
                          {validation.touched.oldPassword && validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Password</Label>
                          <Input
                            type="password"
                            name="newPassword"
                            className="form-control"
                            id="new-password"
                            placeholder="Enter Your New Password"
                            onBlur={validation.handleBlur} onChange={validation.handleChange}
                          />
                          {validation.touched.newPassword && validation.errors.newPassword ? (
                            <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Confirm Password</Label>
                          <Input
                            type="password"
                            name="confirm"
                            className="form-control"
                            id="confirm-password"
                            placeholder="Enter Your Confirm Password"
                            onBlur={validation.handleBlur} onChange={validation.handleChange}
                          />
                          {validation.touched.confirm && validation.errors.confirm ? (
                            <FormFeedback type="invalid">{validation.errors.confirm}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md mt-3">
                        Change Password
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
        </Row>

        <Row>  
          <AdminWallets/>
        </Row>
        
        <Row>  
          <Commission/>
        </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};


Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
