import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as Yup from "yup";
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    FormFeedback,
    Label,
    Form,
  } from "reactstrap";
import { fireToast } from 'common/Toster';
import { get, patch, post } from 'helpers/api_helper';

export default function HashModal({show,setShow, toggle,data,fetchAddFundsRequests,pageNumber,pageSize,search,handleUpdateStatus}) { 
    
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            adminTransactionHash:"",
        },

        validationSchema: Yup.object({
            adminTransactionHash: Yup.string().required("Please Enter Transaction Hash"),
        }),

        onSubmit: async (values) => {
          try {
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };
            
            const response = await patch(`/admin/AddFundHash`, {...values,addfund_id:data._id}, { headers });
            if (response.status == 200) {
              setShow(false)
              fireToast("success", "Transaction Hash Saved Successfully");
              handleUpdateStatus(data._id,"APPROVED")
              fetchAddFundsRequests(pageNumber,pageSize,search)
            }
          } catch (error) {
            fireToast("error", error.response.data.error);
          }
          toggle();
        },
      });
    
      return (
        <div >
         <Modal isOpen={show} toggle={toggle}>
                    <ModalHeader tag="h4" toggle={toggle}>
                       Admin Transaction Hash
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Transaction Hash</Label>
                              <Input
                                name="adminTransactionHash"
                                type="textarea"
                                cols="2" rows="2"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.adminTransactionHash || ""}
                                invalid={
                                  validation.touched.adminTransactionHash && validation.errors.adminTransactionHash ? true : false
                                }
                              />
                              {validation.touched.adminTransactionHash && validation.errors.adminTransactionHash ? (
                                <FormFeedback type="invalid">{validation.errors.adminTransactionHash}</FormFeedback>
                              ) : null}
                            </div>

                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
        </div>
    )
}
