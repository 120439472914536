// ReactApp.js

import React, { useEffect } from 'react';
// import './ReactApp.css'; // Import your CSS file

const TradingView = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="container ">
      {/* <div className="tradingview-widget-container__widget"></div> */}
      <div className="tradingview-widget-copyright ">
        <a href="https://in.tradingview.com/"   >
          <span className="text-dark h2">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TradingView;
