import React from "react"
import { Bar } from "react-chartjs-2"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const BarChart = ({dataColors,monthlyRegistereddata}) => {
  var barChartColor =  getChartColorsArray(dataColors);   
  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July","August","September","October","Novemeber","December"],
    datasets: [
      {
        label: "Users",
        backgroundColor: barChartColor[0],
        borderColor: barChartColor[0],
        borderWidth: 1,
        hoverBackgroundColor: barChartColor[1],
        hoverBorderColor: barChartColor[1],
        data: [monthlyRegistereddata[0]?.total, monthlyRegistereddata[1]?.total, monthlyRegistereddata[2]?.total, monthlyRegistereddata[3]?.total, monthlyRegistereddata[4]?.total, monthlyRegistereddata[5]?.total, monthlyRegistereddata[6]?.total, monthlyRegistereddata[7]?.total,monthlyRegistereddata[8]?.total,monthlyRegistereddata[9]?.total,monthlyRegistereddata[10]?.total,monthlyRegistereddata[11]?.total],
      // data:[12,344,555,]
      },
    ],
  }

  const option = {
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
    },
  }

  return <Bar width={651} height={360} data={data} options={option} />
}

export default BarChart
