import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap"
// import "../../assets/scss/style.css"
// import { Modal, Form, Button } from "react-bootstrap"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"
// import EditPhoto from "./edit"
// import ViewInformation from "./view"
import { fireToast } from "common/Toster"
import axios from "axios"
import DeleteInformation from "./delete"
import Addbanner from "./add"
import { APIS_URL } from "config"



const Viewbanners = props => {
    const API_BASE_URL = `${APIS_URL}uploads/`;
    const [modalView, setModalView] = React.useState(false);
    const [modalDelete, setModalDelete] = React.useState(false);
    const [modalAdd, setModalAdd] = React.useState(false);
    const [photoId, setPhotoId] = useState();
    const [dashboardListing, setDashboardListing] = useState()
    const [addInfo, setAddInfo] = useState(null);
    const [delInfo, setDelInfo] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [userInfo2, setUserInfo2] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchList, setSearch] = useState("");
    const [activeCard, setActiveCard] = useState("A");
    console.log(activeCard, "dddddddd");
    const pageLimit = 10
    console.log(dashboardListing, "dashboardListing");

    useEffect(() => {
        getListing()
    }, [searchList])

    const getListing = async (page = 1) => {
        try {
            console.log("heyyyyy", page)
            const resp = await axios.get(`${APIS_URL}api/user/banners?pageNumber=${page}&pageSize=10`);
            // const resp = await modelslistingimages(searchList, page)
            console.log(resp.data.data.bannerListing, "<<<<<<")
            if (resp.status == 200) {
                setDashboardListing(resp?.data?.data?.bannerListing)
                console.log(dashboardListing, "<<<<<<<<<<<<<<<<<")
                setTotalPages(resp?.data?.data?.totalPages);
                setCurrentPage(page)
            }
            else {
                fireToast('error', resp.error)
            }
        }
        catch (error) {
            fireToast('error', error?.response?.data?.error)
        }
    }
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            getListing(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            getListing(currentPage - 1);
        }
    };

    const startIndex = (currentPage - 1) * pageLimit + 1;

    const productList = [1, 2, 3]

    return (
        <React.Fragment>

            {modalDelete && (
                <DeleteInformation getListing={() => { getListing() }} delInfo={delInfo} show={modalDelete} onHide={() => setModalDelete(false)} />
            )}
            {modalAdd && (
                <Addbanner getListing={() => { getListing() }} addInfo={addInfo} show={modalAdd} onHide={() => setModalAdd(false)} />
            )}
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <CardBody className="border-bottom py-2">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1">
                                        BANNERS
                                    </h5>

                                    <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                        <div
                                            className="position-relative"
                                            style={{ marginTop: "10px" }}
                                        >
                                            <Button
                                                className="btn btn-primary"
                                                onClick={() => { setModalAdd(true); setAddInfo({}) }}
                                            >ADD BANNER</Button>
                                            {/* <label htmlFor="search-bar-0" className="search-label">
                                                <span id="search-bar-0-label" className="sr-only">
                                                    Search this table
                                                </span>
                                                <input
                                                    id="search-bar-0"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search Here..."
                                                    onChange={(e) => { setSearch(e.target.value) }}
                                                />
                                            </label>
                                            <i className="bx bx-search-alt search-icon"></i> */}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>

                            <Row>
                                {
                                    dashboardListing?.map((product, key) => (
                                        <Col xl={6} sm={12} key={"_col_" + key}>

                                            <Card>
                                                <CardBody >
                                                    <div className="product-img position-relative" >
                                                        <img src={API_BASE_URL + product?.image} alt="" className="img-fluid mx-auto d-block"
                                                            style={{ width: '100%', height: '250px' }}
                                                        /> 

                                                        <div className="avatar-xs product-ribbon">

                                                            <span className="avatar-title rounded-circle font-size-15 ms-1"
                                                                style={{ opacity: 0.8, cursor: "pointer" }}
                                                                onClick={() => { setModalDelete(true); setDelInfo(product) }}
                                                            //     onMouseEnter={() => { setModalShow2(true); setUserInfo2(product) }}
                                                            //  onMouseLeave={()=>{ setModalShow2(false)}} 
                                                            >
                                                                <i className="mdi mdi-delete"></i>
                                                            </span>
                                                            {/* <span className="avatar-title rounded-circle font-size-15"
                                                                style={{ opacity: 0.8, cursor: "pointer" }}
                                                                onClick={() => { setModalView(true); setUserInfo2(product) }}
                                                            //     onMouseEnter={() => { setModalShow2(true); setUserInfo2(product) }}
                                                            //  onMouseLeave={()=>{ setModalShow2(false)}} 
                                                            >
                                                                <i className="mdi mdi-eye"></i>
                                                            </span> */}

                                                        </div>
                                                    </div>
                                                    {/* <div className="mt-4 ">
                                                        <h5 className="mb-1 text-truncate text-dark"> */}
                                                    {/* <Link
                                                                    to={"/ecommerce-product-detail/" + "1232"}
                                                                    className="text-dark"
                                                                > */}
                                                    {/* {product?.name} 
                                                            </Link>
                                                         </h5> */}
                                                    {/* <div className="mt-1">
                                                            <div className="text-muted font-size-14 content-line">
                                                                <p>
                                                                    {product?.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="text-muted mb-3"> */}

                                                    {/* </div>  */}
                                                    {/* </div>  */}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                            <Row className="mt-2">
                                {/* <Col md={9}></Col> */}
                                <Col md={6}>
                                    {/* <Pagination
                        {...bootstrap5PaginationPreset} 
                        current={currentPage}
                        total={totalCount}
                        onPageChange={page => handlePageClick(page)}
                        className="pagination justify-content-end"
                      /> */}
                                    <div className="">
                                        {/* {supportList?.length > 10 && ( */}
                                        <a>
                                            <button
                                                className="btn btn-primary"
                                                onClick={handlePreviousPage}
                                                disabled={currentPage === 1}
                                            >
                                                Previous
                                            </button>
                                            <span > {currentPage}   </span>
                                            <button
                                                className="btn btn-primary"
                                                onClick={handleNextPage}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </button>

                                        </a>
                                        {/* )}  */}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

Viewbanners.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(Viewbanners)