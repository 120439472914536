import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "assets/images/slogo.png";
import { forgotPassword } from "services/userServices";
import { useHistory } from "react-router-dom";
import { fireToast } from "common/Toster";

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Forget Password";
  const history = useHistory();
  const validation = useFormik({

    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid User").required("Email is Required"),
    }),
    
    onSubmit: async (values) => {
      try {
        const loginResponse = await forgotPassword(values);
        if (loginResponse.status === 200) {
          history.push("/otp");
          localStorage.setItem("contact-info", values.email);
          fireToast("success", loginResponse.message);
        } else {
          throw new Error(loginResponse.error);
        }
      } catch (err) {
        fireToast("error", err.response.data.error);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages centerd-aligned py-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-theme">
                  <Row>
                    <Col xs={7}>
                      <div className="text-white p-4 pe-1">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p className="text-white">
                          Enter the email to get OTP.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/login" className="auth-logo-light">
                      <div
                        className="avatar-md profile-user-wid mb-4 bg-soft"
                        style={{ height: "4.5rem", width: "5.5rem" }}
                      >
                        <span className="avatar-title rounded border bg-white">
                          <img src={logo} height="45" className="" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-4 mt-4">
                        <Col className="text-center">
                          <button className="btn w-100" type="submit">
                            Reset
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-0 text-center">
                        <p>
                          Go back to{" "}
                          <Link
                            to="login"
                            className="font-weight-medium text-primary"
                          >
                            Login Page
                          </Link>{" "}
                        </p>
                      </div>
                      <Row>
                        <Col md={10}>
                          <div className="text-sm-end d-none d-sm-block">
                            Design & Developed by{" "}
                            <a
                              href="https://blocktechbrew.com/"
                              target="_blank"
                               rel="noreferrer"
                            >
                              BlockTechBrew
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
