import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as Yup from "yup";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip,
    Input,
    FormFeedback,
    Label,
    Form,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
import { fireToast } from 'common/Toster';
import { postAsForm } from 'helpers/api_helper';

export default function AddNetworkModal({show,setShow, toggle}) { 

      const [myimage, setMyImage] = useState("");
      const uploadFile =  (files) => {
        if (files && files.length > 0) {
          setMyImage(files[0].name);
        }
      };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            networkName:  '',
            rpcURL:"",
            chainId:"",
            currencySymbol:"",
            explorerURL:"",
            parentNetwork:"",
            isTestNet:""
        },

        validationSchema: Yup.object({
            networkName: Yup.string().required("Please Enter Network Name"),
            rpcURL: Yup.string().required("Please Enter rpcUrl"),
            chainId: Yup.string().required("Please Enter chainId"),
            currencySymbol: Yup.string().required("Please Enter currencySymbol"),
            explorerURL: Yup.string().required("Please Enter explorerURL"),
            parentNetwork: Yup.string().required("Please Enter parentNetwork"),
        }),

        onSubmit: async (values) => {
          try {
            if(myimage == undefined || myimage == ""){
              toast.error("Image is required.")
            }
            else{
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };
            const response = await postAsForm(`/admin/networks`, {...values,file:myimage}, { headers });
            if (response.status == 200) {
              setShow(false)
              fireToast("success", response.message);
              fetch()
            }
            }
          } catch (error) {
            fireToast("error", error.response.data.error);
          }
          toggle();
        },
      });
    
      const handleCustomerClick = arg => {
        setIsEdit(true);
        toggle();
      };
    
      return (
        <div >
         <Modal isOpen={show} toggle={toggle}>
                    <ModalHeader tag="h4" toggle={toggle}>
                        Add Network
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">NetworkName</Label>
                              <Input
                                name="networkName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.networkName || ""}
                                invalid={
                                  validation.touched.networkName && validation.errors.networkName ? true : false
                                }
                              />
                              {validation.touched.networkName && validation.errors.networkName ? (
                                <FormFeedback type="invalid">{validation.errors.networkName}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Rpc URL</Label>
                              <Input
                                name="rpcURL"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.rpcURL || ""}
                                invalid={
                                  validation.touched.rpcURL && validation.errors.rpcURL ? true : false
                                }
                              />
                              {validation.touched.rpcURL && validation.errors.rpcURL ? (
                                <FormFeedback type="invalid">{validation.errors.rpcURL}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">ChainId</Label>
                              <Input
                                name="chainId"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.chainId || ""}
                                invalid={
                                  validation.touched.chainId && validation.errors.chainId ? true : false
                                }
                              />
                              {validation.touched.chainId && validation.errors.chainId ? (
                                <FormFeedback type="invalid">{validation.errors.chainId}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Currency Symbol</Label>
                              <Input
                                name="currencySymbol"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.currencySymbol || ""}
                                invalid={
                                  validation.touched.currencySymbol && validation.errors.currencySymbol ? true : false
                                }
                              />
                              {validation.touched.currencySymbol && validation.errors.currencySymbol ? (
                                <FormFeedback type="invalid">{validation.errors.currencySymbol}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Explorer URL</Label>
                              <Input
                                name="explorerURL"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.explorerURL || ""}
                                invalid={
                                  validation.touched.explorerURL && validation.errors.explorerURL ? true : false
                                }
                              />
                              {validation.touched.explorerURL && validation.errors.explorerURL ? (
                                <FormFeedback type="invalid">{validation.errors.explorerURL}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Parent Network</Label>
                              <Input
                                name="parentNetwork"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.parentNetwork || ""}
                                invalid={
                                  validation.touched.parentNetwork && validation.errors.parentNetwork ? true : false
                                }
                              />
                              {validation.touched.parentNetwork && validation.errors.parentNetwork ? (
                                <FormFeedback type="invalid">{validation.errors.parentNetwork}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">TestNet</Label>
                              <Input
                                name="isTestNet"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.isTestNet || ""}
                                invalid={
                                  validation.touched.isTestNet && validation.errors.isTestNet ? true : false
                                }
                              />
                              {validation.touched.isTestNet && validation.errors.isTestNet ? (
                                <FormFeedback type="invalid">{validation.errors.isTestNet}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="col-md-5">
                            <label htmlFor="image">Network Image</label>
                            <input
                              className={`form-control`}
                              type="file"
                              id="file"
                              onChange={(e) => uploadFile(e.target.files)}
                            />
                            <div className="mt-3 mb-4">
                              <div className="f-28 fw-900 text-white">{ }</div>
                            </div>
                            </div>

                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
        </div>
    )
}
