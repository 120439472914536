import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Pagination = ({ setPage, page, data }) => {
  const totalPages = Math.ceil(page.totalItems / page.pageSize);

  const handleNext = () => {
    setPage((prevPage) => ({
      ...prevPage,
      current: Math.min(prevPage.current + 1, totalPages),
    }));
  };

  const handlePrev = () => {
    setPage((prevPage) => ({
      ...prevPage,
      current: Math.max(prevPage.current - 1, 1),
    }));
  };
  
  return (
    <div>
      {" "}
      <div className="row mt-lg-5 mt-4">
        <div className="col-lg-8 col-xxl-7 d-flex align-items-center justify-content-between">
          <p className="mb-0 f-14 text-dark d-lg-block d-none">
            Showing {data?.length} of {page.totalItems} entries
          </p>
          <nav className="mr-auto" aria-label="...">
            <ul className="pagination cstm-page">
              <li
                className={`page-item ${page.current <= 1 ? "disabled" : ""}`}
                onClick={handlePrev}
              >
                <span className="page-link">
                  <BsChevronLeft />
                </span>
              </li>
              <li className="page-item">
                <a className="page-link" href="javascript:void(0);">
                  Page
                </a>
              </li>
              <li className="page-item active" aria-current="page">
                <span className="page-link">{page.current}</span>
              </li>
              <li className="page-item">
                <a className="page-link" href="javascript:void(0);">
                  of {totalPages}
                </a>
              </li>
              <li className="page-item" onClick={handleNext}>
                <a className="page-link" href="javascript:void(0);">
                  <BsChevronRight />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
