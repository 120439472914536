import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  Card,
  CardBody,
  Button,
  Badge,
} from "reactstrap";
// import { ethers } from "ethers";
import "./index.scss";
import {
  CButton,
  CFormSelect,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import UserImg from "../../assets/images/user.png";
import axios from "axios";
import Breadcrumb from "components/Common/Breadcrumb";
import moment from "moment";
import schedule from "../../assets/images/schedule.svg";
import Pagination from "common/Pagination";
import Web3 from "web3";
import { fireToast } from "common/Toster";
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg";
import { get, patch } from "helpers/api_helper";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { StandardPicketDateFormat } from "common/utility";
import { ethers } from "ethers";
import {
  handleClickCopy,
  longStringConvertToShort,
} from "common/commonfunction";
import HashModal from "./HashModal";

const web3 = new Web3(Web3.givenProvider);

const AddFund = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [text, setText] = useState("");
  const [modalData, setModalData] = useState({});
  const [copyCheck, setCopyCheck] = useState();
  const [adminHash, setAdminHash] = useState(0);
  const [show, setShow] = useState(false);
  const toggle = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const handleAddClick = (item) => {
    setShow(true);
    setModalData(item);
  };

  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 20,
  });

  const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format("MMM DD, YYYY")}
        <br />
        <small>{moment(date).format("h:mm a")}</small>
      </>
    );
  };

  const fetchAddFundsRequests = async (pageNumber, pageSize, search) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

      const response = await get(
        `/admin/addFundsRequests?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`,
        { headers }
      );

      if (response.status === 200) {
        // console.log(response?.data[0].liveprice);
        setData(response?.data);
        setPage({
          ...page,
          totalItems: response?.data?.data?.totalUsers,
          current: pageNumber,
        });
        // setLivePrice(response?.data?.livePrice?.BWP)
      } else {
        console.log("not fetched data");
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchAddFundsRequests(page.current, page.pageSize, search);
  }, [page.current, page.pageSize, search]);

  const handleRejectTransaction = async (item, status, res) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

      const data = {
        addfund_id: item,
        updated_status: status,
      };

      const response = await patch(`/admin/updateAddFund`, data, {
        headers,
      });

      if (response.status === 200) {
        fetchAddFundsRequests(page.current, page.pageSize, search);
        fireToast("success", response.message);
      } else {
        fireToast("error", 'Deposit Request Rejected Successfully');
      }

      const data2 = {
        adminTransactionHash: res,
        addfund_id: item,
      };
      const response2 = await patch(`/admin/AddFundHash`, data2, {
        headers,
      });

      if (response2.status === 200) {
        fetchAddFundsRequests(page.current, page.pageSize, search);
      } else {
        fireToast("error", response.error);
      }
    } catch (err) {
      setLoader(false);
      fireToast("error", err.response ? err.response.data.error : err);
      console.log(err, "try error");
    }
  };
  const handleUpdateStatus = async (item, status) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

      const data = {
        addfund_id: item,
        updated_status: status,
      };

      const response = await patch(`/admin/updateAddFund`, data, {
        headers,
      });

      if (response.status === 200) {
        fireToast("success", response.message);
        fetchAddFundsRequests(page.current, page.pageSize, search)
      } else {
        fireToast("error", response.error);
      }
    } catch (err) {
      setLoader(false);
      fireToast("error", err.response ? err.response.data.error : err);
      console.log(err, "try error");
    }
  };

  const handleApproveTransaction = async (item) => {
    try {
      const finalamount = item.depositAmount / item?.liveprice?.BWP;
      const userAddress = item.addresses.find(
        (item) => item.name == "ethereum"
      );
      const address = userAddress.address;
      const amount = ethers.utils
        .parseEther(finalamount.toFixed(6).toString())
        .toHexString();
      let chainId = "0x" + item.networkDetails[0].chainId.toString(16);

      if (item.networkDetails[0].chainId == 2494104990) {
        chainId = "0x2494104990";
      }
      if (!window.ethereum) {
        console.log("MetaMask is not installed.");
        return;
      }

      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }], // 0x38 for Binance Smart Chain testnet (BSC Testnet)
      });

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      // Send transaction using MetaMask
      const txReceipt = await window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: accounts[0], // Sender's wallet address
              to: address, // Receiver's wallet address
              value: amount, // Convert value to wei (1 ether)
            },
          ],
        })
        .then((res) => {
          handleRejectTransaction(item._id, "APPROVED", res).then((Res) => {
            fetchAddFundsRequests(page.current, page.pageSize, search);
          });
          fireToast("success", "Successfully Sent.");
        })
        .catch((err) => {
          setLoader(false);
          fireToast("error", err?.message);
        });

      // // Transaction successful
      // console.log("Transaction hash:", txReceipt);
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  return (
    <React.Fragment>
      <HashModal
        show={show}
        setShow={setShow}
        toggle={toggle}
        data={modalData}
        fetchAddFundsRequests={fetchAddFundsRequests}
        pageNumber={page.current}
        pageSize={page.pageSize}
        search={search}
        handleUpdateStatus={handleUpdateStatus}
      />
      <div className="page-content margin-custom">
        <Container fluid>
          <Breadcrumb
            title="Deposit Requests"
            breadcrumbItem="Deposit Requests"
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="col-12 col">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div className="page-title-right">
                        <ol className="breadcrumb ">
                          <li>
                            <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                              <div className="position-relative">
                                <input
                                  onChange={(e) => setSearch(e.target.value)}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control"
                                  placeholder={`Search...`}
                                  value={search}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </li>

                          {/* <li>
                              <div className="search-box me-xxl-2 my-5 my-xxl-0 d-inline-block mx-5">
                              <div className="position-relative">
                                  <CInputGroup>
                                    <DateRangePicker
                                      initialSettings={{ startDate: '1/1/2024', endDate: '3/1/2024' }}
                                      onApply={dateFilterChange}
                                      // onCancel={}
                                      onShow={() => setCalendarIsShowing(true)}
                                      onHide={() => setCalendarIsShowing(false)}
                                    >
                                      <input
                                        ref={inputRef}
                                        placeholder="Filter by Date"
                                        className="form-control"
                                        value={
                                          dateFilter.length
                                            ? `${StandardPicketDateFormat(
                                                dateFilter[0]
                                              )} - ${StandardPicketDateFormat(
                                                dateFilter[1]
                                              )}`
                                            : ""
                                        }
                                      />

                                    </DateRangePicker>
                                    <CInputGroupText>
                                      <img
                                        onClick={() => {
                                          if (
                                            !calendarIsShowing &&
                                            dateFilter.length
                                          ) {
                                            setDateFilter([]);
                                            fetchWithdrawRequests(page.current, page.pageSize, status, [], search)
                                          }
                                        }}
                                        src={
                                          calendarIsShowing
                                            ? calendarremovelines
                                            : dateFilter.length
                                            ? calendarremovelines
                                            : schedule
                                        }
                                        alt=""
                                        width={20}
                                      />
                                      
                                    </CInputGroupText>
                                  </CInputGroup>
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </li> */}

                          {/* <li> */}
                          {/* <div className="me-xxl-2 my-5 my-xxl-0 d-inline-block mx-5">
                              <div className="position-relative">
                                      <CFormSelect
                                        className="form-control"
                                        aria-label="Small select example"
                                        onChange={(e) => changeStatus(e)}
                                        value={status}
                                      >
                                        <option value=""  className="form-control">All</option>
                                        <option value="0">PENDING</option>
                                        <option value="1">COMPLETED</option>
                                        <option value="2">REJECTED</option>
                                      </CFormSelect>
                             
                             </div>
                             </div>
                            </li> */}
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="table-responsive react-table">
                      <Table bordered hover className="custom-header-css">
                        <thead className="table-light table-nowrap">
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">User Name</th>
                            {/* <th scope="col">Phone Number</th> */}
                            <th scope="col">Deposit Amount</th>
                            <th scope="col">Date of Deposit</th>
                            <th scope="col">Request Asset</th>
                            <th scope="col">Receipt</th>
                            {/* <th scope="col">Transaction Hash</th> */}
                            <th scope="col">Admin Transaction Id</th>

                            <th scope="col">Status</th>
                            {/* <th scope="col">Transaction ID</th> */}
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        {data?.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <strong>
                                  {item.userDetails[0].first_name}
                                </strong>
                                <p className="text-primary mt-1">
                                  {item.userDetails[0].email}
                                </p>
                              </td>
                              {/* <td>{item.phoneNumber}</td> */}
                              <td>
                                {item.depositAmount} {item.currencyOfDeposit}
                              </td>
                              <td>{fullDateFormat(item.date)}</td>
                              <td>
                                {item.assetsDetails.symbol}{" "}
                                <strong>
                                  (
                                  {(
                                    item.depositAmount / item?.liveprice?.BWP
                                  ).toFixed(6)}
                                  )
                                </strong>
                              </td>

                              <td>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-sm btn-rounded"
                                  onClick={() => {
                                    // Check if item.receiptImage is provided
                                    if (item.receiptImage) {
                                      // Open the link in a new tab
                                      window.open(item.receiptImage, "_blank");
                                    } else {
                                      // Handle the case where item.receiptImage is not provided
                                      console.error(
                                        "Receipt image URL is not provided."
                                      );
                                    }
                                  }}
                                >
                                  Receipt
                                </Button>
                              </td>

                              {item.status == "PENDING" ? (
                                <td>-</td>
                              ) : (
                                <td>
                                  {item?.adminTransactionHash ? (
                                    <>
                                      <b className="mb-0">
                                        {longStringConvertToShort(
                                          item?.adminTransactionHash,
                                          25
                                        )}{" "}
                                        <i
                                          role="button"
                                          onClick={() =>
                                            handleClickCopy(
                                              item?.adminTransactionHash
                                            )
                                          }
                                          className="mdi mdi-content-copy ms-2"
                                        ></i>{" "}
                                      </b>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              )}


                              <td
                                style={{
                                  fontSize: "18px",
                                }}
                              >
                                <div className="">
                                  <Badge
                                    color={`${
                                      item.status === "DECLINED"
                                        ? "danger"
                                        : item.status === "PENDING"
                                        ? "primary"
                                        : "success"
                                    }`}
                                  >
                                    {item.status}
                                  </Badge>
                                </div>
                              </td>

                              {item.asset === "Solana" ||
                              item.asset === "Tron" ? (
                                item.status === "APPROVED" ? (
                                  <td
                                    className="my-5"
                                    style={{ fontSize: "18px" ,}}
                                  >
                                    <Badge color="success">COMPLETED</Badge>
                                  </td>
                                ) : item.status === "DECLINED" ? (
                                  <td
                                    className="my-5"
                                    style={{ width: "180px", fontSize: "18px" }}
                                  >
                                    <Badge color="danger">REJECTED</Badge>
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      width: "200px",
                                      display: "flex",
                                      gap: "5px",
                                    }}
                                  >
                                    <Button
                                      className="btn btn-sm danger"
                                      style={{
                                        backgroundColor: "#6262e4",
                                        color: "white",
                                        fontSize: "12px",
                                        fontWeight: "800px",
                                      }}
                                      onClick={() => handleAddClick(item)}
                                    >
                                      Enter Hash &nbsp;
                                    </Button>
                                    <Button
                                      className="btn btn-sm danger"
                                      style={{
                                        backgroundColor: "#e21313b5",
                                        color: "white",
                                        fontSize: "13px",
                                        fontWeight: "800px",
                                      }}
                                      onClick={() =>
                                        handleUpdateStatus(item._id, "DECLINED")
                                      }
                                    >
                                      Reject &nbsp;
                                    </Button>
                                  </td>
                                )
                              ) : item.status === "PENDING" ? (
                                <td className="my-0" style={{ width: "180px",display:"flex"}}>
                                  <Button
                                    className="btn btn-sm"
                                    style={{
                                      backgroundColor: "#6262e4",
                                      color: "white",
                                      fontSize:"13px",
                                      display:"flex"
                                    }}
                                    onClick={() =>
                                      handleApproveTransaction(item)
                                    }
                                  >
                                    Send &nbsp;
                                  </Button>
                                  <span>&nbsp; &nbsp; &nbsp;</span>
                                  <Button
                                    className="btn btn-sm danger"
                                    style={{
                                      backgroundColor: "#e21313b5",
                                      color: "white",
                                      fontSize: "13px",
                                      fontWeight: "1000px",
                                      display:"flex"
                                    }}
                                    onClick={() =>
                                      handleRejectTransaction(item, "DECLINED")
                                    }
                                  >
                                    Reject &nbsp;
                                  </Button>
                                </td>
                              ) : item.status === "APPROVED" ? (
                                <td
                                  className="my-5"
                                  style={{ fontSize: "18px" }}
                                >
                                  <Badge color="success">COMPLETED</Badge>
                                </td>
                              ) : (
                                <td
                                  className="my-5"
                                  style={{ width: "180px", fontSize: "18px" }}
                                >
                                  <Badge color="danger">REJECTED</Badge>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </div>
                  {/* <Pagination setPage={setPage} page={page} data={data} /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddFund;
