import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  Card,
  CardBody,
  Button,
  Badge
} from "reactstrap";

import "./index.scss";
import toastr from "toastr";
import { Link } from "react-router-dom";
import { CFormSwitch } from "@coreui/react";
import UserImg from "../../assets/images/user.png";
import axios from "axios";
import Breadcrumb from "components/Common/Breadcrumb";
import moment from "moment";
import Pagination from "common/Pagination";
import { fireToast } from "common/Toster";
import DeleteModal from "components/Common/DeleteModal";
import ViewUserModal from "./ViewUserModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getImage } from "common/getImageUrl";
import { APIS_URL } from "config";

const index = (props) => {

  document.title="Express-Minds User-Management"
  const history=useHistory()
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);

  const [loading, setLoading] = useState(false);
  const [user ,setTotalUser]=useState([]);
  const [copyCheck, setCopyCheck]=useState()
  const [text,setText]=useState("")
  const [showParagraph,setShowParagraph]=useState("")
  const [modal1, setModal1] = useState(false);
  const [item, setItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 10,
  });

  const toggleViewModal = (item) => {
    history.push('/viewUser',{state:item._id});
    // setModal1(!modal1)
    // setItem(item)
  };

  const handleCopy = (data, index) => {
    navigator.clipboard.writeText(data)
    setText("Copied")
    setShowParagraph(true)
    setCopyCheck(index)

    setTimeout(() => {
      setText("")
      setShowParagraph(false)
    }, 2000);
  }


  const userList = async (pageNumber,pageSize,search) => {
   try{
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };

    const response = await axios.get( 
      `${APIS_URL}api/admin/userListing?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      { headers }
    );
    if (response.status === 200) {
      setData(response?.data?.data?.totalItems);
      setTotalUsers(response?.data?.data?.totalUsers)
      setPage({
        ...page,
        totalItems:response?.data?.data?.totalUsers,
        current: pageNumber,
      }); 

    } else {
      console.log("not fetched data");
    }
   }catch(err)
   {
    throw err;
   }
  };


  useEffect(() => {
    userList(page.current, page.pageSize,search);
  }, [page.current, page.pageSize,search]);

  const block_unblock_user = async (
    status,
    id,
    key,
    pageNumber,
    pageSize,
    search
  ) => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };
    try {
      const params = {
        id: id,
        isBlocked: status
      };

      let response = await axios.post(
        `${APIS_URL}api/admin/block-unblock`,
        params,
        { headers }
      );
      if (response.status == 200) {
        fireToast("success",response.data.message);
        userList(pageNumber, pageSize, search);
      } else {
        toastr.error(response?.data?.error ?? "Something went wrong.");
      }
    } catch (error) {
      toastr.error("Something went wrong!");
    }
    setLoading(false);
  };

  const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format('MMM DD, YYYY')}
        <br />
        <small>{moment(date).format('h:mm a')}</small>
      </>
    )
  }

  return (
    <>
     <React.Fragment>
      {/* <ViewUserModal isOpen={modal1} toggle={toggleViewModal} data={item}/> */}
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
      />
        <div className="page-content margin-custom">
          <Container fluid>
            <Breadcrumb title="UsersList" breadcrumbItem="User Management" />
            <Row>
              <Col xs="12">
                <Card>

                  <CardBody>
                    <div className="col-12 col">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div className="page-title-right">
                          <ol className="breadcrumb ">
                            <li>
                              <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                <div className="position-relative">
                                    <input
                                      onChange={(e) => 
                                        setSearch(e.target.value)}
                                      id="search-bar-0"
                                      type="text"
                                      className="form-control"
                                      placeholder={`Search...`}
                                      value={search}
                                    />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </li>
                          </ol>
                        </div>
                        <p className="px-4" style={{fontSize:'18px'}}>Total Users : {totalUsers}</p>
                      </div>
                      
                    </div>

                 
                    
                    <div className="col-12">
                      <div className="table-responsive react-table">
                        <Table bordered hover className="custom-header-css">
                          <thead className="table-light table-nowrap">
                            <tr>
                              <th scope="col">Sr.No</th>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              {/* <th scope="col">Wallet Address</th> */}
                              <th scope="col">Date Of Registration</th>
                              <th scope="col">View Details</th>
                              <th scope="col">KYC Status</th>
                              <th scope="col">Blocked</th>
                              {/* <th scope="col">Action</th> */}
                            </tr>
                          </thead>

                          {data?.map((item, index) => (
                            <tbody key={index}>
                         
                              <tr>
                              <td>
                                  {index +
                                    1 +
                                    (page.current - 1) * page.pageSize}
                                </td>
                                <td><img src={getImage(item.profilePic)} alt="" className="avatar-sm" /></td>
                                <td>
                                  <strong>{item.first_name} {item.last_name}</strong>
                                </td>
                                <td>
                                  <a className="d-block" href="mailto:">
                                    {item.email}
                                  </a>
                                </td>
    
                            {/* <td className="my-3">
                              {item.walletAddress == '0' ? ('-') : (
                                <>
                                  <div title={item.walletAddress}>
                                    {item.walletAddress ? item.walletAddress.slice(0, 5) + '....' +
                                        item.walletAddress.slice(item.walletAddress.length - 5)
                                      : ' - '}{' '}

                                        <span onClick={() => handleCopy(item.walletAddress,index)}>
                                        <i className="mdi mdi-content-copy ms-2 fs-5" style={{ cursor: 'pointer' }} /> 
                                        {showParagraph && <p style={{ color: "darkgreen" }}>{copyCheck == index ? text :""}</p>} 
                                        </span>                                 
                                  </div>
                                </>
                              )}
                            </td> */}

                                <td>{fullDateFormat(item.createdAt)}</td>
                              
                              <td>
                              <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={()=>toggleViewModal(item)}
                              >
                                View Details
                              </Button>
                              </td>

                                <td>
                                    {item.KycStatus === "0"
                                      ?  <Badge color="warning" style={{fontSize:"12px"}}>PENDING</Badge>
                                      : item.KycStatus === "1"
                                      ?  <Badge color="success" style={{fontSize:"12px"}}>COMPLETED</Badge>
                                      : item.KycStatus === "2"
                                      ?  <Badge color="danger" style={{fontSize:"12px"}}>FAILED</Badge>
                                      : "UNKNOWN"}
                                  </td>
                               
                               <td>
                                <CFormSwitch
                                  shape="pill"
                                  className="hand"
                                  style={{ cursor: 'pointer'}}
                                  onChange={(e) => block_unblock_user(e.target.checked, item._id,'isBlocked', page.current,
                                  page.pageSize,
                                  search)}
                                  defaultChecked={item.isBlocked ? 1 : 0}
                                />
                               </td>
                              </tr>
                            </tbody>
                           ))} 
                        </Table>
                      </div>
                    </div>
                    <Pagination setPage={setPage} page={page} data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default index;
