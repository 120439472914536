import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { IMAGE_BASE_URL } from "helpers/api_helper"
import {
    Card,
    CardBody,
    CardText,
    Col,
    Row,
} from "reactstrap";
import { Link } from "react-router-dom"
import moment from "moment"
import { fireToast } from 'common/Toster';
import axios from 'axios';
import { APIS_URL } from 'config';

const DeleteInformation = ({ delInfo, onHide, show, getListing }) => {             //, setModalShow, setUserInfo, setModalShow2 }) => {

    // const { photoId } = props;
    const [detail, setDetail] = useState({ ...delInfo });
    console.log(detail, onHide, show, "detail on viw new")
    // const [file, setFile] = useState()
    console.log(delInfo, "<<-- delInfo")
    const API_BASE_URL = `${APIS_URL}uploads/`;

    const deleteBanner =  async (values) => {
        try {

                console.log(delInfo, "<<-- values")
                let token = JSON.parse(localStorage.getItem("authUser")).token;
                console.log(token,"token")
                const response = await axios.delete(`${APIS_URL}api/admin/banner?bannerId=${delInfo?._id}`,
                    {
                        headers: { Authorization: token },
                    })
                if (response.status == 200) {
                    fireToast('success', response.data.message)
                    onHide();
                    getListing()
                  } else {
                    throw new Error(response.data.error);
                  }
        } catch (error) { 
            console.log(error.message)
            fireToast('error', error?.response?.data?.error)
        }
    }


    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ height: "100%" }}
                className='img-view-modal'
            >
                <Modal.Header closeButton className=''>
                    
                </Modal.Header>

                <Modal.Body> 
                    <div>
                    <h5 className=' text-center'>ARE YOU SURE YOU WANT TO DELETE THIS BANNER?</h5>
                    <div className="d-flex justify-content-center">
                        <button
                            className="btn btn-primary mt-3"
                            onClick={()=>deleteBanner()}
                        >
                            Delete
                        </button> 
                    </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteInformation
