import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import axios from 'axios';
import { post } from 'helpers/api_helper';
import { fireToast } from 'common/Toster';
import { APIS_URL } from 'config';

const Privacy = ({ onChange }) => {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());

  const handleOnChange = (editorValue) => {
    setValue(editorValue);
    if (onChange) {
      onChange(editorValue.toString('html'));
    }
  };

  const updateContent = async () => {
    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const htmlContent = value.toString('html');
    try {
      let response = await post(`${APIS_URL}api/admin/content`,
         { 
           content: htmlContent,
           title: 'privacyPolicy'
         }, 
         {
         headers: { Authorization: token },
         }
      );
     if(response.status===200)
     {
      fireToast("success",response.message)
     }
    } catch (error) {
      fireToast("failed",response.message)
    }
  };

  useEffect(() => {
    const fetchTermAndConditions = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("authUser")).token;
        const response = await axios.get(`${APIS_URL}api/user/getsinglecontent?title=privacyPolicy`,   {
          headers: { Authorization: token },
        });
        if (response.data && response.data.data.content) {
          setValue(RichTextEditor.createValueFromString(response.data.data.content, 'html'));
        }
      } catch (error) {
        fireToast('error', error?.response?.data?.error)
      }
    };
    fetchTermAndConditions();
  }, []);

  return (
    <div className="page-content margin-custom">
      <Container fluid>
        <Row>
          <div className="col-12 col">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Privacy Policy</h4>
            </div>
          </div>
          <div className="col-12">
            <RichTextEditor value={value} onChange={handleOnChange} />
            <div className="text-center mt-4">
              <button className="btn btn-primary d-inline-block w-auto" onClick={updateContent}>
                Update
              </button>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

Privacy.propTypes = {
  onChange: PropTypes.func,
};

export default Privacy;
