import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import "./index.scss";
import {
  CFormSelect,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import UserImg from "../../assets/images/user.png";
import axios from "axios";
import Breadcrumb from "components/Common/Breadcrumb";
import moment from "moment";
import schedule from "../../assets/images/schedule.svg";
import Pagination from "common/Pagination";
import { fireToast } from "common/Toster";
import calendarremovelines from "../../assets/images/calendar-remove-lines.svg";
import { get, patch, put } from "helpers/api_helper";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { StandardPicketDateFormat } from "common/utility";

const Withdraw = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const inputRef = useRef(null);
  const [calendarIsShowing, setCalendarIsShowing] = useState(false);
  const [dateFilter, setDateFilter] = useState([]);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 20,
  });

  const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format("MMM DD, YYYY")}
        <br />
        <small>{moment(date).format("h:mm a")}</small>
      </>
    );
  };

  const dateFilterChange = (e, picker) => {
    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ];
    setDateFilter(filter);
  };

  const changeStatus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const statusVal = e.target.value;
    if (statusVal === status) {
      return;
    }
    setStatus(statusVal);
  };

  const fetchWithdrawRequests = async (
    pageNumber,
    pageSize,
    search,
    withdrawStatus,
    dateFilter = []
  ) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

      const [fromDate, toDate] = dateFilter || [];
      const response = await get(
        `/admin/withdrawrequests?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}&fromDate=${
          fromDate == undefined ? "" : fromDate
        }&toDate=${
          toDate == undefined ? "" : toDate
        }&withdrawStatus=${withdrawStatus}`,
        { headers }
      );

      if (response.status === 200) {
        setData(response?.data?.items);
        setPage({
          ...page,
          totalItems: response?.data?.data?.totalUsers,
          current: pageNumber,
        });
      } else {
        console.log("not fetched data");
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchWithdrawRequests(
      page.current,
      page.pageSize,
      search,
      status,
      dateFilter
    );
  }, [page.current, page.pageSize, search, status, dateFilter]);


  const handleWithdrawRequest=async (id,status)=>{

    const token = JSON.parse(localStorage.getItem("authUser")).token;
    const headers = { Authorization: token };

    console.log(id,'id',status,"?>>>");
    const response=await patch('/admin/updatewithdrawrequest',{withdraw_id:id,withdraw_status:status},{headers})
    if(response.status===200)
    {
      fireToast('success',response.message)
      fetchWithdrawRequests(  page.current,
        page.pageSize,
        search,
        '',
        dateFilter)
    }
    else
    {
      fireToast('error',response.error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          <Breadcrumb
            title="Withdraw Requests"
            breadcrumbItem="Withdraw Requests"
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="col-12 col">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div className="page-title-right">
                        <ol className="breadcrumb ">
                          <li>
                            <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                              <div className="position-relative">
                                <input
                                  onChange={(e) => setSearch(e.target.value)}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control"
                                  placeholder={`Search...`}
                                  value={search}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="search-box me-xxl-2 my-5 my-xxl-0 d-inline-block mx-5">
                              <div className="position-relative">
                                <CInputGroup>
                                  <DateRangePicker
                                    initialSettings={{
                                      startDate: "1/1/2024",
                                      endDate: "3/1/2024",
                                    }}
                                    onApply={dateFilterChange}
                                    // onCancel={}
                                    onShow={() => setCalendarIsShowing(true)}
                                    onHide={() => setCalendarIsShowing(false)}
                                  >
                                    <input
                                      ref={inputRef}
                                      placeholder="Filter by Date"
                                      className="form-control"
                                      value={
                                        dateFilter.length
                                          ? `${StandardPicketDateFormat(
                                              dateFilter[0]
                                            )} - ${StandardPicketDateFormat(
                                              dateFilter[1]
                                            )}`
                                          : ""
                                      }
                                    />
                                  </DateRangePicker>
                                  <CInputGroupText>
                                    <img
                                      onClick={() => {
                                        if (
                                          !calendarIsShowing &&
                                          dateFilter.length
                                        ) {
                                          setDateFilter([]);
                                          fetchWithdrawRequests(
                                            page.current,
                                            page.pageSize,
                                            status,
                                            [],
                                            search
                                          );
                                        }
                                      }}
                                      src={
                                        calendarIsShowing
                                          ? calendarremovelines
                                          : dateFilter.length
                                          ? calendarremovelines
                                          : schedule
                                      }
                                      alt=""
                                      width={20}
                                    />
                                  </CInputGroupText>
                                </CInputGroup>
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="me-xxl-2 my-5 my-xxl-0 d-inline-block mx-5">
                              <div className="position-relative">
                                <CFormSelect
                                  className="form-control"
                                  aria-label="Small select example"
                                  onChange={(e) => changeStatus(e)}
                                  value={status}
                                >
                                  <option value="" className="form-control">
                                    All
                                  </option>
                                  <option value="0">PENDING</option>
                                  <option value="1">COMPLETED</option>
                                  <option value="2">REJECTED</option>
                                </CFormSelect>
                              </div>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="table-responsive react-table">
                      <Table bordered hover className="custom-header-css">
                        <thead className="table-light table-nowrap">
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">User Name</th>
                            {/* <th scope="col">Asset Name</th> */}
                            <th scope="col">Withdraw Amount</th>
                            <th scope="col">Amount in Fiat</th>
                            <th scope="col">Bank Account Name</th>
                            <th scope="col">Bank Account Number</th>
                            <th scope="col">Date Of Withdraw Request</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        {data?.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <strong>
                                  {item.userDetails[0].first_name}
                                </strong>
                              </td>
                              {/* <td>{item.asset}</td> */}
                              <td>{item.amount} {item.asset}</td>
                              <td>{(item.amount*item.liveprice.BWP).toFixed(6)} BWP</td>
                              <td>{item.bankDetails[0].name}</td>
                              <td>{item.bankDetails[0].acc_number}</td>
                              <td>{fullDateFormat(item.createdAt)}</td>
                              <td>
                                {item.status === "0" ? (
                                  <Badge
                                    color="warning"
                                    style={{ fontSize: "12px" }}
                                  >
                                    PENDING
                                  </Badge>
                                ) : item.status === "1" ? (
                                  <Badge
                                    color="success"
                                    style={{ fontSize: "12px" }}
                                  >
                                    COMPLETED
                                  </Badge>
                                ) : item.status === "2" ? (
                                  <Badge
                                    color="danger"
                                    style={{ fontSize: "12px" }}
                                  >
                                    REJECTED
                                  </Badge>
                                ) : (
                                  "UNKNOWN"
                                )}
                              </td>

                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  href="#"
                                  className="card-drop"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      handleWithdrawRequest(item._id,1);
                                    }
                                    }
                                  >
                                    <i
                                      className="bx bx-check font-size-16 text-success me-2"
                                      id="edittooltip"
                                    ></i>
                                    Transfer
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="edittooltip"
                                    >
                                      Transfer
                                    </UncontrolledTooltip>
                                  </DropdownItem>

                                  <DropdownItem
                                    href="#"
                                    onClick={() => {
                                      handleWithdrawRequest(item._id,2);
                                    }}
                                  >
                                    <i
                                      className="bx bx-x font-size-16 text-danger me-2"
                                      id="deletetooltip"
                                    ></i>
                                    Reject
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="deletetooltip"
                                    >
                                      Reject
                                    </UncontrolledTooltip>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </div>
                  {/* <Pagination setPage={setPage} page={page} data={data} /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Withdraw;
