import React from "react"
import { Pie } from "react-chartjs-2"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const PieChartUser = ({dataColors,usersData}) => {
  var pieChartColors =  getChartColorsArray(dataColors); 
  const data = {
    labels: ["Total Users", "Verified User","Blocked User"],
    datasets: [
      {
        data: [usersData.totalUser, usersData.verifiedUser,usersData.blockedUser],
        backgroundColor: pieChartColors,
        hoverBackgroundColor: pieChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  }

  return <Pie width={650} height={460} data={data} />
}

export default PieChartUser;
