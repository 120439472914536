import React, { useEffect, useState } from 'react'
import { Container, Row, Table, Form } from 'react-bootstrap'; // Import Form from react-bootstrap
import { get, patch, put, post } from "helpers/api_helper";
import { fireToast } from 'common/Toster'
import CompanySupportReply from 'components/Modals/CompanySupport/CompanySupportReply'
import pendingImg from '../../assets/images/pending.png'
import resolvedImg from '../../assets/images/resolved.png'
import axios from "axios";
import { APIS_URL } from 'config';

const Support = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalAction, setModalAction] = useState(null);
    const [selectedSupport, setSelectedSupport] = useState(null); // Add state for selected support
    const [supportList, setSupportList] = useState([]);
    const [supportCount, setSupportCount] = useState([]);
    const [supportId, setSupportId] = useState();
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [query_status, setQueryStatus] = useState('ALL');
    const [searchSupport, setSearchSupport] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Added state for total pages
    const pageLimit = 10;

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";  // Or any default value you prefer
        }
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            //   timeZoneName: 'short',
            timeZone: 'GMT',
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
        return formattedDate;

    };
    const getSupportList = async (page = 1) => {
        try {
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };
            const resp= await axios.get(`${APIS_URL}api/support/getAll?pageNumber=${page}&pageSize=${pageLimit}&fromDate=${start_date}&toDate=${end_date}&filter=${searchSupport}&status_type=${query_status}`,{headers});
            console.log(resp.data.data,"Resss")
            if (resp.status == 200) {
                setSupportCount(resp?.data?.data?.totalItems)
                setSupportList(resp.data?.data?.items)
                setTotalPages(resp.data?.data?.pages); // Set the total pages from the API response
                setCurrentPage(page);
                // fireToast('success', resp.message)
            } else {
                fireToast('error', resp?.error)
            }
        } catch (error) {
            console.log(error);
            fireToast('error', error?.response?.data?.error)
        }
    }

    const handleGetSupportList = async () => {
        await getSupportList(currentPage);
    };
    const handleCategoryClick = (status) => {
        setQueryStatus(status);
    };

    const handleStartDateChange = async (e) => {
        const selectedStartDate = e.target.value;
        setStartDate(selectedStartDate);
        await getSupportList(currentPage); // Call API when start date is selected
    };

    // Event handler for end date change
    const handleEndDateChange = async (e) => {
        const selectedEndDate = e.target.value;
        setEndDate(selectedEndDate);
        await getSupportList(currentPage); // Call API when end date is selected
    };

    const handleClearDates = () => {
        setStartDate('');
        setEndDate('');
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            getSupportList(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            getSupportList(currentPage - 1);
        }
    };
    const startIndex = (currentPage - 1) * pageLimit + 1;
    const endIndex = (currentPage - 1) * pageLimit + pageLimit;

    const getPageRange = () => {
        return `${startIndex}-${endIndex}`;
    };
    const truncateText = (text, maxLength) => {
        return text?.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    useEffect(() => {
        getSupportList()
    }, [query_status, start_date, end_date, searchSupport])

    return (
        <>
            <CompanySupportReply
                show={modalShow}
                onHide={() => setModalShow(false)}
                Message={supportId}
            />
            <React.Fragment>
                <div className="page-content margin-custom support-element">
                    <Container fluid>
                        <Row>
                            <div className="col-12 col">
                                <div className="page-title-box row align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Company Support Queries</h4>
                                    <div className="page-title-right d-flex gap-x-5 align-items-center justify-content-end">

                                        <button className={`text-center border border-primary rounded-3 w-100 py-2 shadow p-1 text-primary   ${query_status === '' ? 'bg-primary bg-opacity-25' : 'bg-white '}`} onClick={() => handleCategoryClick('ALL')} style={{ marginTop: '10px' }}>
                                            All {supportCount?.totalItems}
                                        </button>
                                        <button className={`text-center w-100 py-2 border border-warning rounded-3 shadow p-1 text-warning ${query_status === 'PENDING' ? 'bg-warning  bg-opacity-25' : 'bg-white'}`} onClick={() => handleCategoryClick('PENDING')} style={{ marginTop: '10px' }}>
                                            Pending {supportCount?.pending_length}
                                        </button>
                                        <button className={`text-center w-100 py-2 border border-success rounded-3 shadow p-1 text-success ${query_status === 'SOLVED' ? 'bg-success  bg-opacity-25' : 'bg-white'}`} onClick={() => handleCategoryClick('SOLVED')} style={{ marginTop: '10px' }}>
                                            Resolved {supportCount?.successful_length}

                                        </button>
                                        <input className='form-control' type="text" placeholder='Search'
                                            onChange={(e) => { setSearchSupport(e.target.value) }} style={{ marginTop: '10px' }}
                                        />
                                        <Form.Group className="mb-3">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={start_date}
                                                onChange={handleStartDateChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={end_date}
                                                onChange={handleEndDateChange}
                                            />
                                        </Form.Group>
                                        <button className='btn btn-primary border-0' style={{ marginTop: '10px' }} onClick={handleClearDates}>Clear</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Table className=" table-nowrap align-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "100px" }}>
                                                #
                                            </th>
                                            <th scope="col">Ticket Id</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    {supportList.length === 0 ? (
                                        <p>No Data Found</p>
                                    ) : (
                                        <tbody>
                                            {
                                                supportList?.map((val, i) => (
                                                    <tr key={i}>
                                                        <td>{startIndex + i}</td>
                                                        {val?.querySolveStatus === 'SOLVED' && (
                                                            <td>
                                                                <a className="link-blue" onClick={() => {
                                                                    setModalAction('details');
                                                                    setModalShow(true);
                                                                    setSelectedSupport(val, 'details');
                                                                }}>{val?._id || 'NA'}</a>
                                                            </td>
                                                        )}
                                                        {val?.querySolveStatus === 'PENDING' && (
                                                            <td>
                                                                {val?._id || 'NA'}
                                                            </td>
                                                        )}
                                                        <td>{val?.email}</td>
                                                        <td><p>{truncateText(val?.subject, 20)}</p></td>
                                                        <td><p>{truncateText(val?.message, 20)}</p></td>
                                                        <td><p>{formatDate(val?.createdAt)}</p></td>
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            {val?.querySolveStatus === 'SOLVED' && (
                                                                <div className="d-flex align-items-center">
                                                                    <p>
                                                                        <img src={resolvedImg} alt="resolved Img" className='status-icon' style={{ marginRight: '5px' }} />
                                                                        <span style={{ color: '#52AA60' }}>Resolved</span>
                                                                    </p>
                                                                </div>
                                                            )}
                                                            {val?.querySolveStatus === 'PENDING' && (
                                                                <div className="d-flex align-items-center">
                                                                    <p>
                                                                        <img src={pendingImg} alt="pending Img" className='status-icon' style={{ marginRight: '5px' }} />
                                                                        <span style={{ color: '#E7872B' }}>Pending</span>
                                                                    </p>
                                                                </div>
                                                            )}
                                                            {val?.querySolveStatus !== 'SOLVED' && val?.querySolveStatus !== 'PENDING' && (
                                                                <span style={{ color: 'black' }}>{val?.querySolveStatus}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {val?.querySolveStatus === 'SOLVED' ? (
                                                                <div>
                                                                    <p>
                                                                        <button
                                                                            className='btn btn-success border-0'
                                                                            disabled
                                                                            style={{ pointerEvents: 'none' }}
                                                                        >
                                                                            Replied
                                                                        </button>
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <p>
                                                                    <button
                                                                        className='btn btn-primary border-0'
                                                                        onClick={() => {
                                                                            setModalAction('reply'); setModalShow(true); setSelectedSupport(val);
                                                                        }}>
                                                                        Reply
                                                                    </button>
                                                                </p>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    )}
                                </Table>

                            </div>
                            <div className="col-12">
                                <div className="">
                                    {/* {supportList?.length > 10 && ( */}
                                    <a>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handlePreviousPage}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        <span > {currentPage}   </span>
                                            <button
                                                className="btn btn-primary"
                                                onClick={handleNextPage}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </button>
                                        
                                    </a>
                                    {/* )}  */}
                                </div>
                            </div>
                            <CompanySupportReply
                                show={modalShow}
                                onHide={() => {
                                    setModalShow(false);
                                    setSelectedSupport(null); // Reset selected support item when hiding modal
                                    setModalAction(null); // Reset modal action
                                    handleGetSupportList();
                                }}
                                message={selectedSupport?._id}
                                subject={selectedSupport?.subject}
                                messageContent={selectedSupport?.message}
                                adminMessage={selectedSupport?.response_message}
                                action={modalAction}
                            />
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

export default Support