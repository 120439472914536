import { get } from "helpers/api_helper";
import React, { useEffect, useState, useMemo } from "react";
import {
    Button,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
    UncontrolledDropdown,
    UncontrolledTooltip,
  } from "reactstrap";
import AddNetworkModal from "./AddNetworkModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Form } from "react-bootstrap";
  
export default function AddNetwork() {
    
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [show,setShow]=useState(false)
    const[active,setActive]=useState(false);
    
    const networkList = async () => {
        const token = JSON.parse(localStorage.getItem("authUser")).token;
        const headers = { Authorization: token };

        const response = await get(`/admin/networks?pageNumber=1&pageSize=20`, { headers });
        if (response.status === 200) {
          setData(response?.data?.items);
        } else {
          console.log("not fetched data");
        }
      };
      
    const toggle = () => {
        if (show) {
          setShow(false);
        } else {
          setShow(true);
        }
    };

    useEffect(() => {
        networkList();
    }, [search]);


    const handleAddClick  =()=>{
        setShow(true)
    }
    
    const handleChange = async (e) => {
              if(e.target.value=="on")
              {
              
              }
    }

  return (
    <>
      <React.Fragment>
      <AddNetworkModal show={show} setShow={setShow} toggle={toggle}/>
        <div className="page-content margin-custom">
          <Container fluid>
            <Row>
              <div className="col-12 col">
                <div className="page-title-box pb-2 d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Network List
                  </h4>
                
                  <div className="page-title-right">
                    <ol className="breadcrumb ">
                      <li>
                        {" "}
                        <span className="form-group has-search position-relative">
                          <span className="fa fa-search form-control-feedback position-absolute "></span>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            className="form-control"
                            placeholder="Search"
                          />
                        </span>
                      </li>
                    </ol>
                  </div>
                </div>
                <div>
                <Col sm="12">
            <div className="text-sm-end d-flex justify-content-end ">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2 "
                onClick={handleAddClick}
              >
                <i className="mdi mdi-plus me-1" />
                Add Network
              </Button>
            </div>
          </Col>
                </div>
              </div>
              <div className="col-12">
                <div className="table-responsive vh-100">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Network Name</th>
                        <th scope="col">RpcURL</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    {data.length > 0 &&
                      data.map((item, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                          <img
                              src={item.networkImage}
                              alt="user_icon"
                              className="user-icon"
                            /> <strong>{item.name}</strong>
                          </td>
                          <td>
                            <strong>{item.rpcUrl}</strong></td>
                          <td>
                            <Form.Check 
                              type="switch"
                              id={`custom-switch-${index}`}
                              label=""
                              className="fs-5"
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                      ))}
                  </Table>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}
